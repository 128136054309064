.web-exit-proto-container-main {
  background: var(--medium-bg-color);
  border: none !important;
  height: 800px;
  color: var(--common-white-color);
  overflow-x: hidden;
}

.web-exit-proto-container-main {
  overflow: hidden;
  overflow-y: scroll;
}

.web-exit-proto-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.web-exit-proto-form {
  border-radius: 25px 25px 25px 25px;

  width: 1000px;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  justify-content: center;
}
.web-exit-proto-form .web-exit-proto-tradeEmotion {
  background-color: var(--trade-emotion-bg-color);

  padding: 20px 30px 10px 30px;
}
.web-exit-proto-form h1 {
  color: var(--common-white-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.web-exit-proto-form .web-exit-proto-mainHedingExitproto {
  color: var(--common-white-color);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.web-exit-proto-form p {
  color: var(--main-primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.web-exit-proto-select1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.web-exit-proto-one-right button {
  background: transparent;
  color: var(--comman-grayBtn-color);
  padding: 2px 5px;
  font-size: 10px;
  border-radius: 5px;
  border: 1px solid #acada6;
}
.web-exit-proto-select2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.web-exit-proto-two-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
.web-exit-proto-sp-line {
  width: 2px;
  height: 85px;
  background: var(--hit-exit-btn-bg);
}
.web-exit-proto-two-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
.web-exit-proto-tdpBox {
  width: 110px;
  padding: 10px;
  height: 85px;
  flex-shrink: 0;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(254, 162, 57, 0.08) 0%,
    rgba(202, 85, 59, 0.078) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.web-exit-proto-greenBox {
  width: 110px;
  padding: 10px;

  height: 85px;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--green-box-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.web-exit-proto-wrapper .web-exit-proto-greenBox h1 {
  font-size: 20px;
}
.web-exit-proto-wrapper .web-exit-proto-tdpBox h1 {
  font-size: 20px;
}
.web-exit-proto-select3 {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 10px 0px;
}
.web-exit-proto-select3 .web-exit-proto-three-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 1em;
}
.web-exit-proto-three-right .web-exit-proto-crl-box {
  width: 107px;
  height: 107px;
  background-color: var(--history-table-head-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.web-exit-proto-astro-images-circle-selection {
  gap: 5px;
}
.web-exit-proto-crl-box h3 {
  color: var(--common-white-color);
  font-weight: 400;
  font-size: 16px;
}
.web-exit-proto-selectHasnum {
  display: flex;
  align-items: center;

  justify-content: left;
  gap: 1em;
}
.web-exit-proto-wrapper .web-exit-proto-hasnumber {
  background-color: var(--main-primary-color);

  text-align: center;
  padding: 8px 12px;
  border-radius: 55px;
}
.web-exit-proto-wrapper .web-exit-proto-hasnumber h2 {
  margin: 0;
  color: var(--main-bg-color);
  font-size: 18px;
  font-weight: 600;
}
.web-exit-proto-selectionTag h2 {
  color: var(--main-primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.web-exit-proto-selectionTag h1 {
  color: var(--common-white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.web-exit-proto-select4 {
  display: flex;
  align-items: center;

  padding: 30px;
}
.web-exit-proto-four-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.web-exit-proto-wrapper .web-exit-proto-four-right input {
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--dropdown-field-bg-color);
  width: 100%;
  height: 30px;
  padding: 8px 32px;
  color: var(--common-white-color);
  margin: 0;
  font-size: 16px;
  box-sizing: content-box;
}

.web-exit-proto-wrapper .web-exit-proto-currentNotesInputPlus {
  font-size: 16px;
  color: var(--common-white-color);
}

.web-exit-proto-wrapper .web-exit-proto-newstrategyBtncontainer button {
  width: 45px !important;
  height: 45px;
  background-color: var(--main-primary-color);
  border: none;
  color: var(--main-bg-color);
  font-size: 26px;
  font-weight: 600;
  border-radius: 2px;
  padding: 0px;
}
.web-exit-proto-wrapper .web-exit-proto-newstrategyBtncontainer > button:hover {
  background-color: var(--main-primary-color);
  opacity: 0.8;
}

.web-exit-proto-wrapper .web-exit-proto-currentNotesInputPlus > button {
  width: 45px !important;
  height: 45px;
  background-color: var(--main-primary-color) !important;
  border: none;
  color: var(--main-bg-color);
  font-size: 26px;
  font-weight: 600;
  border-radius: 2px;
  padding: 0px;
}
.web-exit-proto-wrapper .web-exit-proto-currentNotesInputPlus > button:hover {
  background-color: var(--main-primary-color) !important;
  opacity: 0.8;
}
.web-exit-proto-criteriaBtnTopShine {
  width: 15px;
  height: 10px;
  position: absolute;
}
.web-exit-proto-criteriaBtnBottomShine {
  width: 15px;
  height: 10px;
  position: absolute;
}
.web-exit-proto-wrapper .web-exit-proto-criteriaBtn {
  border-radius: 55px;
  background: var(--hit-exit-btn-bg);
  padding: 0.8em 1.2em;
  color: var(--hit-exit-btn-color);
  position: relative;
}
.web-exit-proto-select5 {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background: var(--trade-emotion-bg-color);
}
.web-exit-proto-five-right {
  display: flex;

  justify-content: center;
  gap: 1em;
}
.web-exit-proto-exit-criteria {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 50%;
}
.web-exit-proto-exit-criteria h4 {
  color: var(--you-can-change-color);
  opacity: 1;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 16px;
}
.web-exit-proto-select6 {
  display: flex;
  padding: 20px 30px;
  align-items: center;
}
.web-exit-proto-six-right {
  display: flex;
  width: 75%;

  gap: 2.3em;
}

.web-exit-proto-hitBtn {
  border-radius: 55px;
  background: var(--hit-exit-btn-bg);
  width: 200px;
  height: 38px;
  color: var(--hit-exit-btn-color);
}

.web-exit-proto-select7 {
  display: flex;
  padding: 20px 30px 10px 30px;
  align-items: center;
  justify-content: space-between;
}

.web-exit-proto-wrapper .web-exit-proto-select7 .web-exit-proto-micBtn {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--dropdown-field-bg-colo);
  margin: 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.web-exit-proto-wrapper .web-exit-proto-select7 input {
  width: 650px;
  height: 30px;
  padding: 8px 32px;
  border-radius: 4px;

  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--dropdown-field-bg-colo);
  margin: 0;
  box-sizing: content-box;
  color: var(--hit-exit-btn-color);
  font-size: 16px;
}

.web-exit-proto-wrapper .web-exit-proto-select9 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.web-exit-proto-wrapper .web-exit-proto-select9 .web-exit-proto-submitBtn {
  width: 500px;
  height: 50px;
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  border-radius: 55px;
  font-weight: 600;
  padding: 0;
  font-size: 22px;
}

.web-exit-proto-form .web-exit-proto-ExitbankniftytagLine {
  margin: 20px 0px;
  font-size: 12px;
}
.web-exit-proto-four-right-btn-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.web-exit-proto-exitThreeLeft {
  width: 25%;
  display: flex;
  justify-content: left;
}

.web-exit-proto-exitSelectLeftWidth {
  width: 25%;
  justify-content: left;
}

.web-exit-proto-exitSelectRightWidth {
  width: 75%;
  display: flex;
  justify-content: space-between;
}
.web-exit-protoform .web-exit-proto-angryastroimg {
  margin-left: 18px;
}
.web-exit-proto-wrapper .web-exit-proto-form .web-exit-proto-radiobtnBox {
  display: none;
}
.web-exit-proto-currentNotesInputPlus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.web-exit-proto-form .web-exit-proto-astroImg {
  height: 130px;
}
.web-exit-proto-select8 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 15px 30px 30px 30px;
  padding-left: 205px;
  gap: 10px;
}
.web-exit-proto-wrapper .web-exit-proto-form .css-9l3uo3 {
  font-weight: 600 !important;
}
.web-exit-proto-select5
  .web-exit-proto-five-right
  .web-exit-proto-exit-criteria
  .web-exit-proto-select-all-criteria-box-check {
  color: var(--common-white-color);
  opacity: 0.45;
}

.web-exit-proto-select5
  .web-exit-proto-five-right
  .web-exit-proto-exit-criteria
  .web-exit-proto-select-all-criteria-box-check.Mui-checked {
  color: var(--main-primary-color);
  opacity: 1;
}
.web-exit-proto-select8
  .web-exit-proto-currentNotes
  .web-exit-proto-currents-notes-wrapper-num-remove
  .web-exit-proto-entry-exit-btn-minus {
  width: 16px;
  height: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #fea338; */
  margin: 0;
  padding: 0;
  background: transparent;
  color: var(--main-primary-color);
  /* font-size: 12px; */
  font-weight: 600;
}
.web-exit-proto-select8
  .web-exit-proto-currentNotesPeraNumber
  .web-exit-proto-currentNotesIndex {
  border-radius: 55px;
  display: flex;
  width: 24px;
  height: 26px;
  padding: 15px;
  color: var(--main-bg-color);
  font-weight: 600;
  font-size: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-primary-color);
}
.web-exit-proto-select8
  .web-exit-proto-currentNotesPeraNumber
  .web-exit-proto-currentNotesPera {
  color: var(--common-white-color);
  max-width: 600px;
  width: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.web-exit-proto-select8 .web-exit-proto-currentNotesPeraNumber {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px;
  gap: 10px;
  padding: 0px;
}
.web-exit-proto-select8 .web-exit-proto-currents-notes-wrapper-num-remove {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  border-radius: 50px;
  padding: 10px 20px;
  gap: 10px;
  margin-bottom: 15px;
  background: linear-gradient(
    rgba(254, 163, 56, 0.08) 0%,
    rgba(202, 85, 59, 0.08) 100%
  );
}
.web-exit-proto-select8
  .web-exit-proto-currentNotesPeraNumber
  .web-exit-proto-currentNotesIndex {
  display: flex;
  width: 22px;
  height: 22px;
  color: var(--main-bg-color);
  font-weight: 800;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-primary-color);
  border-radius: 55px;
  padding: 15px;
}
.web-exit-proto-newstrategyBtncontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#web-exit-proto-newStrategy,
.web-exit-proto-version-select-exitproto {
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background-color: var(--light-bg-color);

  width: 231px;
  height: 45px;
  padding: 12px 30px;
  align-items: flex-start;
}

.web-exit-proto-six-right .hit-sl-box,
.hit-target-box,
.hit-panic-box {
  border-radius: 55px;
  background-color: var(--hit-exit-btn-bg);
  color: var(--common-white-color);
  width: 200px;

  padding: 10px;
  text-align: center;
  margin: 0 !important;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.web-exit-proto-five-right .css-1jaw3da {
  max-width: 340px;

  text-align: center;
  border-radius: 55px;
  font-size: 13px;
  padding: 14px 16px;
  background-color: var(--hit-exit-btn-bg);
  font-weight: 600;
  margin: 0;
  color: var(--common-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.web-exit-proto-wrapper .web-exit-proto-select5 .web-exit-proto-five-right {
  justify-content: flex-start;
  gap: 3em;
}

.web-exit-proto-crl-img-wrapper.checked {
  border: 3px solid rgba(254, 162, 56, 0.72);
  background-color: transparent;
  height: 130px;
  width: 130px;

  & h3 {
    color: var(--main-primary-color);
  }
}
input {
  background-color: var(--exit-proto-input-bg);
}
.web-exit-proto-entry-box-btn h3 {
  font-size: 13px;
  line-height: 1.2;
}
.web-exit-proto-entry-box-btn.checked {
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  font-size: 13px;
  font-weight: 600;
}

.web-exit-proto-exit-box-btn h3 {
  font-size: 13px;
  line-height: 1.2;
  font-weight: 600;
}
.web-exit-proto-exit-box-btn.checked {
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  font-size: 13px;
  font-weight: 600;
}

.hit-sl-box.checked {
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  font-weight: 600;
}
.hit-target-box.checked {
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  font-weight: 600;
}

.hit-panic-box.checked {
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  font-weight: 600;
}

.web-exit-proto-crl-img-wrapper {
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.web-exit-proto-exit-criteria
  .web-exit-proto-exit-entry-criteria-condition-btn {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.web-exit-proto-exit-box-btn {
  background-color: var(--hit-exit-btn-bg);
  padding: 10px 10px;
  border-radius: 55px;
  text-align: center;
}
.web-exit-proto-crl-img-wrapper {
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.web-exit-proto-select6
  .web-exit-proto-six-right
  .web-exit-proto-exit-reason-buttons-hit {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 30px;
}

.web-exit-proto-wrapper
  .web-exit-proto-five-right
  .web-exit-proto-exit-entry-criteria-condition-btn
  .web-exit-proto-selectAll-check-box {
  width: 110px;
  background: none;
  padding: 0px;
}
.web-exit-proto-radiobtnBox {
  display: none;
}

.web-exit-proto-three-right .web-exit-proto-crl-box.checked {
  background-color: transparent;
}
