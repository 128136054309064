.loading-msg-content {
  width: 100%;
  height: 100%;
  position: relative;
  /* overflow: hidden; */
}

.loading-filter-blur {
  width: 100%;
  height: 100%;

  position: absolute;
  color: white;
  font-size: 18px;
  text-align: center;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(4px);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #191818ab;
  padding: 1em 2em;
}

.more-details-support {
  border: 1px solid rgba(255, 255, 255, 0.404) !important;
  outline: none !important;
  margin-top: 5px;
  position: relative;
}

.comment-square__arrow {
  border: 1px solid rgba(255, 255, 255, 0.404);
  border-bottom: none;
  border-right: none;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  right: calc(50% - 8px);
  transform: translatey(-9px) rotate(45deg);
  background-color: var(--light-bg-color);
}
