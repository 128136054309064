.main-content {
  /* display: grid;

  gap: 25px; */
  background: rgba(24, 24, 24, 0.75);
  padding: 0 2em 2em;

  .main-content-left {
    display: grid;
    gap: 30px;
    grid-template-rows: repeat(1, 1fr);

    .roi-co-pilot-wraper {
      display: grid;
      grid-template-columns: 40% 58%;
      gap: 25px;
      @media only screen and (min-width: 1920px) {
        grid-template-columns: 610px 1fr;
      }
    }
  }

  .main-content-right {
    display: flex;
    align-items: center;
    justify-content: center;
    .wizer-chart {
      height: 312px;
      transform: rotate(-0.6deg);
      & img {
        width: 80%;
      }
    }
  }
  .roi-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    border-radius: 10em 25px 25px 10em !important;
    position: relative;
    height: 346px;

    .roi-circle {
      & span {
        color: #ff6550;
        font-weight: 700;
      }
    }
  }

  .win-trade,
  .lose-trade {
    width: 100%;
    height: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1em 1.5em;

    & p {
      margin: 0.5em 0 0;
      font-size: 1.2rem;
      font-weight: 400;
    }
    & span {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }

  .win-trade {
    background: rgba(103, 184, 75, 0.12);
    position: relative;
    border-radius: 5em 25px 25px 0em;
    & span {
      color: #67b84b;
    }
  }
  .win-lose-wrapper {
    position: absolute;
    right: 20px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .lose-trade {
    background: rgba(226, 91, 61, 0.12);
    position: relative;
    border-radius: 0em 25px 25px 5em;
    & span {
      color: #ff6550;
    }
  }
  .pnl-section {
    .daily-btn {
      & button {
        background: var(--main-primary-color);
        color: var(--main-bg-color);
        width: 107px;
      }
    }
    .cumulative-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2em;
      & button {
        background: var(--light-bg-color);
        color: var(--common-white-color);
        width: 126px;
      }
    }

    & button {
      border-radius: 55px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.064px;
      border: none;
      height: 30.335px;
    }

    .pnl-header-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 1.3em 1.3em 0;
      & h3 {
        color: var(--common-white-color);
        font-size: 1.5rem;
        font-weight: 600;

        & span {
          color: var(--main-primary-color);
        }
      }
    }
  }
  .roi-section,
  .co-pilot-insight-section,
  .pnl-section,
  .main-content-right {
    border-radius: 25px;
    border: 1px solid #373737;
    background-color: var(--secondary-bg-color);
  }
}
.roi-co-pilot-wraper .main-content-right {
  padding: 1em;
  text-align: center;
}

.trade-score-section {
  padding: 0 2em 2em;
  .trade-score {
    height: 504px;
    width: 100%;
  }
  .trade-score-header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
  }
  .trade-score-header-data-list {
    width: 12vw;
    padding: 0.8em 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid rgba(251, 161, 55, 0.06);
    background: rgba(251, 161, 55, 0.06);

    & h5 {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
    }
    & span {
      color: #b6c233;
      font-size: 1.5rem;
      font-weight: 600;
    }
    .rr {
      color: #ff7d7d;
    }
  }
  .trade-score-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1em;
    h3 {
      padding: 0;
    }
  }
}
.trade-plan-discipline-copilot-wrap {
  display: grid;
  grid-template-columns: 65% 1fr;
  padding: 0 2em 2em;
  gap: 25px;
  .co-pilot-scroll-div {
    height: 70%;
  }
}

.table-wrapper {
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--darkblue);
    border-radius: 40px;
  }

  ::-webkit-scrollbar-track {
    background: var(--white);
    border-radius: 40px;
  }
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  .title {
    color: var(--common-white-color);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
    padding-left: 2rem;
  }
}

.table-wrapper table th,
.table-wrapper table td {
  padding: 1px 5px;
  min-width: 8vw;
  font-size: 1rem;
}

.table-wrapper table th {
  color: var(--white);
  background: var(--darkblue);
}

.table-wrapper table tbody tr:nth-of-type(even) > * {
  background: var(--medium-bg-color);
}

#table-wrapper-dash .dashboard-tradeplandiscipline-table td:first-child {
  display: grid;
  text-align: left;
  padding: 1em 1em 1em 2em !important;
  .trade-plan-sub-text {
    opacity: 0.55;
    font-size: 0.8rem;
  }
}
.table-credits {
  font-size: 12px;
  margin-top: 10px;
}
.pnl-by-time-of-day-section {
  padding: 0 2em 2em;
  .pnl-by-time-of-day {
    height: 508px;
    width: 100%;
  }
}
.instrument-pnl-performance-wrap {
  display: grid;

  grid-template-columns: 1fr;

  .inst-perf-grid {
    /* display: grid; */
    /* grid-template-columns: 1fr; */
    /* gap: 25px; */
    height: 400px;
    width: 100%;
    padding: 1em;
    .inst-perf-grid-left {
      display: grid;
      gap: 10px;
      grid-template-rows: 122px 186px 91px;
    }
    .inst-perf-grid-right {
      display: grid;
      gap: 10px;
      grid-template-rows: 1fr 40%;
    }
    .inst-perf-grid-card {
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: rgba(24, 24, 24, 0.75);
      font-weight: 600;
    }
    .bankNifty,
    .hdfc {
      background-color: #ffc42d;
    }
    .nifty,
    .finNifty {
      background-color: var(--green-color);
    }
    .reliance {
      background-color: #ca553b;
    }
  }
}
.emotion-section {
  padding: 2em;
  height: auto;
  .emotion-status-section {
    margin: 4em 0;
    display: grid;
    gap: 25px;
    .emotion-status-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;
      .emotion-stauts-title {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 36px;
        .happy {
          color: #fea338;
        }
        .zen {
          color: #81d383;
        }
        .angry {
          color: #c3354c;
        }
        .panic {
          color: #b735c3;
        }
        .sad {
          color: #c23434;
        }
      }
      .emotion-stauts-progress-bar-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        background: #262626;
        height: 60px;
        padding: 0 1.5em;

        .happy-img {
          width: 3.5em;
        }
        .zen-img {
          width: 3em;
        }
        .angry-img {
          width: 4.5em;
        }
        .panic-img {
          width: 4.5em;
        }
        .sad-img {
          width: 4.5em;
          height: 80px;
        }
        .emotion-progress-bar {
          width: 100%;
          .happy-progressbar {
            height: 10px;
            box-shadow: 0px 0px 12px 0px rgba(254, 163, 56, 0.75);
            .progress-bar {
              background: linear-gradient(180deg, #fea338 0%, #da831c 100%);
            }
          }
          .zen-progressbar {
            height: 10px;

            box-shadow: 0px 0px 12px 0px rgba(56, 254, 64, 0.55);
            .progress-bar {
              background: linear-gradient(
                180deg,
                rgba(200, 252, 133, 0.92) 0%,
                #7fd283 100%
              );
            }
          }
          .angry-progressbar {
            height: 10px;

            box-shadow: 0px 0px 12px 0px rgba(194, 52, 52, 0.55);
            .progress-bar {
              background: linear-gradient(180deg, #c23434 0%, #c23478 100%);
            }
          }
          .panic-progressbar {
            height: 10px;

            box-shadow: 0px 0px 12px 0px #b735c3;
            .progress-bar {
              background: linear-gradient(180deg, #b735c3 0%, #b735c3 100%);
            }
          }
          .sad-progressbar {
            height: 10px;

            box-shadow: 0px 0px 12px 0px rgba(194, 52, 52, 0.55);
            .progress-bar {
              background: linear-gradient(180deg, #c23434 0%, #c23478 100%);
            }
          }
        }
      }
    }
  }
  .emotion-content {
    height: 100%;
    border-top: 3px solid var(--light-bg-color);
    position: relative;
    padding: 4em 0;
    .emotion-avrage-score {
      width: 425px;
      height: 120px;
      border-radius: 15px;
      background: var(--secondary-bg-color);
      display: flex;
      align-items: center;
      gap: 25px;
      & img {
        width: 6em;
      }
      .emotion-title-text {
        & p {
          color: #fff;
          font-size: 1rem;
          font-weight: 600;
          line-height: 30px;
          margin: 0;
        }
        & span {
          color: var(--main-primary-color);
          font-size: 2rem;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }
    .emotion-title {
      background: var(--main-bg-color);
      color: var(--main-primary-color);
      font-weight: 600;
      font-size: 1.2rem;
      position: absolute;
      border-radius: 3em;
      padding: 0.5em 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -2.5em;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

.pnl-section-heading-and-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.trade-score-heading-data-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1.3em 0em 1.3em;
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  /* .main-content {
    grid-template-columns: 1fr;
  } */
  .trade-plan-discipline-copilot-wrap {
    grid-template-columns: 1fr;
  }
  .co-pilot-insight-section .co-pilot-scroll-div .co-pilot-scroll-div-item {
    justify-content: left;
  }
  .instrument-pnl-performance-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.adherance-table-heading {
  margin-top: 3em;
}
.table-wrapper table thead .trade-plan-table-heading {
  min-width: 14vw;
}

.co-pilot-insight-section .co-pilot-scroll-div-tradPlanD-content {
  height: 20em !important;
}

@media screen and (min-width: 1000px) and (max-width: 1250px) {
  .trade-score-section .trade-score-header-data-list {
    padding: 0.5em 0.3em;
  }
}

.spider-graph-chart {
  padding: 0.5em;
  color: #fff;
}

.trade-circle-container {
  position: absolute;
  left: 30px;
  width: 205px;
  height: 310px;
}
.roi-trade-circle {
  background-color: #434046;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 44%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.win-trade-circle {
  width: 130px;
  height: 130px;
  background-color: #434046;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
  overflow: hidden;
  top: 28%;
  left: 108%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lose-trade-circle {
  width: 150px;
  height: 150px;
  background-color: #434046;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
  overflow: hidden;
  top: 70%;
  left: 108%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.roi-circle,
.lose-circle,
.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  gap: 5px;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 11111111;

  & p {
    margin: 0;
    line-height: 1;
  }
}

.roi-inner-circle {
  background: var(--main-primary-color);
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  transform-origin: top center;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
}

.roi-inner-circle::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 20px;
  border-radius: 50%;
  background-color: var(--water-level-bg-big-circle);
}

.inner-circle {
  background-color: var(--water-level-bg-small-circle);
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  transform-origin: top center;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
}
.lose-inner-circle {
  background-color: var(--water-level-bg-medium-circle);
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  transform-origin: top center;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
}

.inner-circle {
  background-color: var(--water-level-bg-small-circle);

  &::before {
    background-color: var(--water-level-bg-small-3d);
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 20px;
    border-radius: 50%;
  }
}
.lose-inner-circle {
  background-color: var(--water-level-bg-medium-circle);

  &::before {
    background-color: var(--water-level--bg-medium-3d);
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 20px;
    border-radius: 50%;
  }
}
.roi-inner-circle-positive {
  background: var(--main-primary-color);
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  transform-origin: top center;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
}

.roi-inner-circle-positive::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 20px;
  border-radius: 50%;
  background-color: var(--water-level-bg-big-circle);
}
.roi-inner-circle-negative {
  background: var(--water-level-bg-medium-circle);
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
  transform-origin: top center;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.55) inset;
}

.roi-inner-circle-negative::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 20px;
  border-radius: 50%;
  background-color: var(--water-level--bg-medium-3d);
}

@media only screen and (min-width: 800px) and (max-width: 1300px) {
  .main-content .main-content-left .roi-co-pilot-wraper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .main-content .main-inside-content-wizScore {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1920px) {
  .trade-plan-discipline-copilot-wrap {
    grid-template-columns: 1140px 1fr;
  }
}

.main-content .main-inside-content-wizScore {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#headerConfirmCancelButtons {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  background-color: #232323;
  padding: 0.2em 1em;
}

#headerConfirmCancelButtons button {
  width: 45%;
  background-color: #fba137 !important;
  color: #181818;
}
.padding-left-top-zero {
  padding-top: 0;
  padding-left: 0;
}
.circle-page-header-tooltip {
  opacity: 1 !important;
  font-size: 16px !important;
  cursor: pointer !important;
}
@media only screen and (min-width: 1401px) and (max-width: 1700px) {
  .main-content {
    & .main-content-left {
      .roi-co-pilot-wraper {
        display: grid;
        grid-template-columns: 40% 58%;
        gap: 0px;
        justify-content: space-between;
      }
    }
  }

  .main-content {
    .roi-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .roi-co-pilot-wraper .main-content-right {
    padding-left: 0;
  }
}
.cir-roi-text-web {
  font-size: 26px;
  line-height: 28px;
}
@media only screen and (min-width: 1451px) and (max-width: 1550px) {
  .main-content {
    & .main-content-right {
      .wizer-chart {
        width: 450px;
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .trade-plan-discipline-copilot-wrap {
    grid-template-columns: 1fr;
  }
  .emotion-section {
    & .emotion-status-section {
      & .emotion-status-grid {
        .emotion-stauts-title {
          font-size: 0.9rem;
        }
      }
    }
  }

  .roi-trade-circle {
    left: 100px;
    top: 155px;
  }
  .lose-trade-circle {
    top: 210px;
    left: 240px;
  }
  .win-trade-circle {
    top: 90px;
    left: 240px;
  }
  .trade-score-section {
    & .trade-score-header-data-list {
      padding: 0.5em;
      & span {
        font-size: 1rem;
      }
      & h5 {
        font-size: 0.5rem;
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .main-content {
    & .main-content-left {
      .roi-co-pilot-wraper {
        display: grid;
        grid-template-columns: 40% 58%;
        gap: 0px;
        justify-content: space-between;
      }
    }
  }

  .main-content {
    .main-content-right {
      .wizer-chart {
        transform: scale(0.6) rotate(-0.6deg);
        width: 280px;
      }
    }
  }
  .main-content .main-inside-content-wizScore {
    justify-content: flex-start;
    gap: 0px;
    height: 280px;
  }

  .main-content {
    .win-trade,
    .lose-trade {
      justify-content: center;
    }
  }
  .wizr-wing-transform {
    transform: scale(0.8);
  }
  .main-content .win-lose-wrapper {
    transform: scale(0.8);
    right: -10px;
  }
  .trade-circle-container .lose-trade-circle {
    transform: scale(0.8) translate(-50%, -50%);
    top: 60%;
    left: 99%;
  }
  .trade-circle-container .win-trade-circle {
    transform: scale(0.8) translate(-50%, -50%);
    left: 99%;
  }
  .trade-circle-container .roi-trade-circle {
    transform: scale(0.8) translate(-50%, -50%);
    top: 45%;
    left: 44%;
  }
  .main-content {
    .roi-section {
      height: 280px;
    }
  }
  .trade-circle-container {
    left: -10px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .main-content {
    .win-trade,
    .lose-trade {
      justify-content: flex-end;
    }
  }
}
@media screen and (min-width: 1351px) and (max-width: 1600px) {
  .main-content {
    .win-lose-wrapper {
      width: 72%;
    }
  }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
  .main-content .main-inside-content-wizScore {
    gap: 30px;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
  .main-content .main-inside-content-wizScore {
    gap: 30px;
    justify-content: center;
  }
  .main-content {
    & .main-content-right {
      .wizer-chart {
        width: 310px;
      }
    }
  }
  .main-content {
    .win-trade,
    .lose-trade {
      justify-content: center;
    }
  }
}

@media screen and (min-width: 1501px) and (max-width: 1600px) {
  .main-content .main-inside-content-wizScore {
    gap: 80px;
    justify-content: center;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .main-content {
    .main-content-right {
      .wizer-chart {
        transform: scale(0.8) rotate(-0.6deg);
        width: 480px;
      }
    }
  }

  .main-content {
    .win-trade,
    .lose-trade {
      padding-left: 60px;
    }
  }
}

@media screen and (min-width: 1051px) and (max-width: 1200px) {
  .main-content {
    .win-lose-wrapper {
      width: 75%;
    }
  }
}

@media screen and (min-width: 950px) and (max-width: 1051px) {
  .main-content {
    .win-lose-wrapper {
      width: 70%;
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 949px) {
  .main-content {
    .win-lose-wrapper {
      width: 65%;
    }
  }
}

@media screen and (min-width: 1630px) and (max-width: 2000px) {
  .gauge-meter-one {
    transform: scale(0.95);
  }
}

@media screen and (min-width: 1401px) and (max-width: 1629px) {
  .gauge-meter-one {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .gauge-meter-one {
    width: 100px;
  }
}

/* //css for custome calender  */

.timeSelectContainer {
  display: none;
}

.input-group {
  display: none;
}
.activeNotifier {
  visibility: hidden;
}
.daterangepicker {
  background-color: var(--main-bg-color) !important;
  color: var(--common-white-color) !important;
}

.daterangepicker.daterangepicker-layout-open {
  display: flex !important;
  gap: 15px;
}

.daterangepicker.daterangepicker-layout-close {
  display: none !important;
}

#daterangepicker {
  right: 0;
  left: unset !important;
}

#daterangepicker .rangecontainer {
  display: flex !important;
  flex-direction: column !important;
}

.fromDateHourContainer {
  border: 1px solid #777578b2 !important;
}
.dateTimeLabel {
  color: var(--main-primary-color) !important;
  padding-top: 2.5px !important;
  padding-bottom: 2.5px !important;
}
#MonthSelector_start,
#MonthSelector_end,
#YearSelector_start,
#YearSelector_end {
  background-color: #262626 !important;
  color: var(--main-primary-color) !important;
  border-radius: 5px !important;
}
.calendarGrid {
  color: var(--common-white-color) !important;
}

.applyButton,
.cancelButton {
  background-color: transparent !important;
  border: 1px solid var(--main-primary-color) !important;
  color: var(--main-primary-color) !important;
}
.calendarGrid {
  gap: 5px;
  margin-bottom: 5px;
}

.calendarCell {
  border-radius: 4px !important;
}
.calender-input-date-range {
  border: 1px solid #fba137;
  border-radius: 0.5em;
}
.border-none-for-input {
  border: none !important;
}
.border-none-for-input:focus-visible {
  outline: none !important;
}
