.side-bar-wrapper {
  padding: 2em 0em 2em 0em;
  position: fixed;
  width: 8em;
  height: 100vh;
  .side-bar {
    border-radius: 0 25px 25px 0;
    background: var(--light-bg-color);

    height: 100%;
    .sdie-bar-item-bottom-text-wraper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 80%;
    }
    .side-bar-bottom-text p span {
      color: #44bc59;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 1.1px;
    }
    .side-bar-bottom-text p {
      color: rgba(255, 255, 255, 0.85);
      font-size: 0.8rem;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.6px;
    }
    .side-bar-bottom-text {
      text-align: center;
      padding: 1em 0.5rem;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      padding-top: 3em;
    }
    .side-bar-items-wrapper {
      display: grid;
      gap: 18px;
      text-align: center;
      .side-bar-item p {
        color: var(--common-white-color);
        font-size: 0.8em;
        font-weight: 600;
        line-height: 16px;
        opacity: 0.85;
        margin: 0;
      }
      .side-bar-item:hover svg path {
        stroke: var(--main-primary-color);
      }
      .active svg path {
        stroke: var(--main-primary-color);
      }
      .active {
        background-image: linear-gradient(
          to right,
          rgba(251, 161, 55, 0.25),
          rgba(38, 38, 38, 0.25)
        );
        color: var(--main-primary-color);
      }
      .side-bar-item {
        /* padding: 1em 0.5em; */
        height: 86px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-image: linear-gradient(
            to right,
            rgba(251, 161, 55, 0.25),
            rgba(38, 38, 38, 0.25)
          );
          color: var(--main-primary-color);
        }
      }
    }
  }
}
@media only screen and (max-height: 800px) {
  .side-bar-wrapper .side-bar .logo {
    padding-top: 0;
  }
  .side-bar-wrapper .side-bar .side-bar-items-wrapper {
    grid-gap: 0px;
    gap: 0;
  }
  .roi {
    height: 25vh;
  }
  .winDays,
  .lossDays {
    height: 25vh;
  }
}

.add-the-widget-btn-sidebar {
  color: var(--main-primary-color);
  border: 1px solid var(--main-primary-color);
  cursor: pointer;
  border-radius: 15px;
  margin-bottom: 15px;
  opacity: 0.8;
}

.add-the-widget-btn-sidebar:hover {
  opacity: 1;
  border: 2px solid var(--main-primary-color);
}

@media only screen and (min-height: 495px) and (max-height: 600px) {
  .side-bar-wrapper {
    & .side-bar {
      & .side-bar-items-wrapper {
        .side-bar-item {
          height: 60px;
        }
      }
    }
  }
  .side-bar-wrapper {
    & .side-bar {
      .sdie-bar-item-bottom-text-wraper {
        justify-content: flex-start;
      }
    }
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .side-bar-wrapper {
    width: 6em;
  }
  .side-bar-wrapper {
    & .side-bar {
      & .side-bar-items-wrapper {
        .side-bar-item p {
          font-size: 0.6em;
          line-height: 14px;
        }
      }
    }
  }
  .side-bar-wrapper {
    & .side-bar {
      & .side-bar-items-wrapper {
        .side-bar-item {
          height: 55px;
        }
      }
    }
  }
  .add-the-widget-btn-sidebar {
    font-size: 14px;
  }
}
