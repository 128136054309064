.main {
  min-height: 100vh;
  background-color: var(--main-bg-color);
  color: var(--common-white-color);
}
.content {
  background-image: url(../assets/dot-bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 8em;
  min-height: 100vh;
}
.without-bg-image-content {
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 8em;
  min-height: 100vh;
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .content {
    margin-left: 6em;
  }
  .without-bg-image-content {
    margin-left: 6em;
  }
}
