.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;

  width: 100%;

  z-index: 11111;
  background: var(--main-bg-color);

  .header-left-section {
    & h3 {
      color: var(--common-white-color);
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
      & span {
        color: var(--main-primary-color);
      }
    }
  }
  .header-right-section {
    display: flex !important;
    align-items: center;
    gap: 16px;
    ::-webkit-calendar-picker-indicator {
      background-color: #fba137;
      border-radius: 0.2em;
    }
    /* & svg{
          fill: var(--main-primary-color);
        } */
    & input {
      background: none;
      color: white;
      border: 1px solid #fea338;
      width: 190px;
      padding: 0.6em 1em;
      border-radius: 0.5em;
    }
    & .syncnow-button-header {
      border-radius: 55px !important;
      background: var(--main-primary-color);
      padding: 0.5em 1em;
      width: 10vw;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--main-bg-color);
      font-weight: 700;
      border: none;
      outline: none;
      box-shadow: none;
    }
    .trader-progress {
      display: flex;
      align-items: center;
      border-radius: 15px;
      background-color: var(--light-bg-color);
      height: 66px;
      padding: 1em 0 1em 1em;
      gap: 10px;
      .progress-data-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        align-items: flex-end;
        line-height: normal;
        & span {
          opacity: 0.35;
        }
      }
      & p {
        margin-bottom: 0.5em;
      }
      .progress {
        height: 0.5em;
        .progress-bar {
          background-color: #b6c233;
        }
      }
    }
  }
}
.header-date-picker-celender-btn button {
  width: 18vw;
  height: 45px;
  padding: 8px 32px;
  border-radius: 4px;
  border: 1px solid #fea338 !important;
  background: rgba(254, 163, 56, 0.03);
  align-items: center;
  justify-content: space-around;
}
.broker-id-select-menu-btn {
  height: 45px !important;

  padding: 8px 40px;
  border-radius: 4px;
  border: 1px solid #fea338 !important;
  background: rgba(254, 163, 56, 0.03) !important;
  align-items: center;
  justify-content: space-around;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .header .header-right-section .header-date-picker-celender-btn button {
    width: auto;
  }
}

.select-broker-popup {
  background-color: var(--light-bg-color);
  color: var(--main-primary-color);
}

.broker-dropdown-container {
  background: var(--light-bg-color) !important;
  z-index: 11111111111111 !important;
}
#navbar {
  position: fixed;
  width: calc(100% - 8em);

  transition: top 0.3s; /* Add smooth transition effect */
}
.calender-popover {
  z-index: 11111111111111 !important;
}

.syncnow-button-header:hover {
  opacity: 0.8;
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .header {
    height: 65px;

    padding: 0 1em;

    & .header-left-section {
      & h3 {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
  .header {
    & .header-right-section {
      & .syncnow-button-header {
        padding: 0.5em 0.5em;
        width: 14vw;
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .header-date-picker-celender-btn button {
    width: 21vw;
    font-size: 10px;
    height: 45px;
    padding: 8px 20px;
    border-radius: 4px;
  }
  .broker-id-select-menu {
    & button {
      font-size: 10px;
    }
  }
}
