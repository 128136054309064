.sign-up {
  background-color: #181818;
  width: 100%;
  min-height: 100vh;
  color: #fea338;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2em 0em;
}
.sign-up button:hover {
  cursor: pointer !important;
}
.sign-up-left {
  border-radius: 0px 25px 25px 0px;
  background: #262626;
  width: 50%;
  height: 96%;

  flex-direction: column;

  gap: 1em;
  padding: 2em 0em;
  flex-shrink: 0;
}
.sign-up span {
  color: #fff;
}
.sign-up .lable {
  color: #fff;
  opacity: 0.35;
  margin-top: 1em;
  margin-bottom: 0.2em;
}
.sign-up p {
  color: rgba(255, 255, 255, 0.55);

  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 1.1px;
}
.sign-up h2 {
  color: #fea338;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 280px;
  text-align: center;
}
.sign-up h1 {
  color: #fea338;

  font-size: 2.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 2.1px;
  text-align: center;
}
.sign-up h4 {
  color: #fea338;

  text-align: center;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 1.2px;
}
.sign-up .logo-text-container {
  gap: 0.5em;
  height: 15vh;
}

.sign-up .reg-log-button {
  border-radius: 55px;
  background: #fea338;
  width: 227.85px;
  height: 49px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 16px;
  color: #181818;
}

.sign-up .sign-up-right {
  flex-direction: column;

  gap: 1em;
  width: 50%;
}
.sign-up-right input {
  display: flex;
  width: 349px;
  height: 49px;
  padding: 8px 30px;

  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: rgba(254, 163, 56, 0.03);
  color: #fea338;
}
.sign-up-right .input-indian-number {
  padding-left: 50px;
}

.sign-up-right .create-forget-btn button {
  color: #fea338;
  text-align: center;
  background: none;
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-decoration-line: underline;
}
.right-content-logo {
  margin-bottom: 2em;
  width: 8vw;
  height: 8vh;
}
.sign-up-right-content-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logo-text-container p {
  font-size: 18px;
}
.sign-up-right h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.logo-text-container p {
  margin-bottom: 0%;
}
.logo-text-container h5 {
  margin-bottom: 0%;
}

@media screen and (min-width: 900px) and (max-width: 1440px) {
  .sign-up-left {
    gap: 0.1em;
  }

  .sign-up .lable {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
}
.indian-code-phone {
  position: absolute;
  left: 7%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
}
.password-signup {
  position: relative;
}

.iconSPasswordR {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.sign-up-right-content .signupPage-input-error {
  width: 350px;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 0.3em;
  color: red;
}
.support-email-signup p {
  font-size: 18px;
}
