/* swiper-css */
.strategy-analysis-section {
  padding: 2em;

  border-radius: 15px;
  background: rgba(24, 24, 24, 0.75);
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-wrapper {
  margin-bottom: 4em;
}
.swiper-pagination-bullet-active {
  background-color: gray;
}
.swiper-slide {
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .Strength-weekness-botton-card {
    border-radius: 3em;
    padding: 1em 2em;
    background: linear-gradient(
      180deg,
      rgba(254, 163, 56, 0.08) 0%,
      rgba(202, 85, 59, 0.08) 100%
    );
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Strength-weak-wrap {
      display: flex;
      align-items: center;
      gap: 16px;
      & div {
        text-align: left;
      }
      & p {
        margin: 0;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 22px;
      }
      & span {
        color: #ffb763;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
  .win-rate-percentage-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 80%;
    margin: 1em auto;
    .win-rate-percentage-grid-box {
      border-radius: 20px;
      padding: 0.5em;
      background: linear-gradient(
        180deg,
        rgba(254, 163, 56, 0.08) 0%,
        rgba(202, 85, 59, 0.08) 100%
      );
      & p {
        margin: 0;
        color: #fea338;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
      }
      & span {
        color: #fff;
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
  }
  .profit-charges-wrapper {
    border-radius: 15px;
    border: 2px solid;

    width: 80%;
    margin: auto;
    text-align: center;
    padding: 1em;
    & h2 {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 400;
      & img {
        margin-right: 0.5em;
      }
      .profit-span {
        color: #ffb763;
      }
      .charges-span {
        color: #f08989;
      }
    }
  }
  .swiper-inside-card-title {
    margin-bottom: 0.5em;
    & p {
      color: rgba(255, 255, 255, 0.35);
      font-size: 1.2rem;
      font-weight: 400;
      margin: 0;
    }
    & span {
      color: #f90;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
  .swiper-inside-card-bottom-text {
    color: #f90;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .swiper-inside-card-bottom-sub-tex {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
    margin: auto;
  }
  .swiper-inside-card {
    margin-top: 7em;
    padding-top: 7em;
    width: 80%;
    background: #2a231b;
    position: relative;
    border-radius: 3em;
    .inside-cardlogo {
      padding: 0;
      width: 16.5em;
      position: absolute;
      top: -11em;
      left: 50%;
      transform: translate(-50%, 50%);

      .wizer-score-title-data-wrap {
        right: 6.1em;
      }
    }
  }
  .swiper-inside-card-top-text {
    border-radius: 55px;
    background: linear-gradient(
      285deg,
      rgba(228, 123, 26, 0.12) 18.91%,
      rgba(255, 153, 0, 0.12) 101.36%
    );
    color: #f90;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    width: 10rem;
    margin: auto;
    padding: 0.5em;
  }
  .swiper-inside-card-top-sub-text {
    color: #d3d3d3;
    font-size: 1.2rem;
    margin-top: 1em;
    font-weight: 600;
  }
}

/* table css start */

/* Page Wrapper/Container Style */
.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}
/* Responsive Table Style */
.responsive-table {
  background-color: var(--main-bg-color);

  border-collapse: collapse;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  width: 100%;
  margin: 2rem 0;
  overflow: hidden;
}
.table-class-even-color {
  background: #222222;
  box-shadow: inset 0px 0px 5px 5px rgba(26, 26, 26, 0.717);

  border: 0.5px solid rgba(26, 26, 26, 0.717);
}
.table-class-odd-color {
  /* background: var(--main-bg-color); */
  background: #262626;
  border: 0.5px solid rgba(26, 26, 26, 0.717);
  border-bottom: 0.5px solid rgba(26, 26, 26, 0.717);
  border-top: none;
}
.first-row-border {
  border-bottom: 0.5px solid rgba(26, 26, 26, 0.717);
}

.responsive-table__row {
  display: grid;
  padding: 0 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.responsive-table__row th,
.responsive-table__row td {
  padding: 1rem;
}

.responsive-table__head {
  background-color: var(--history-table-head-bg);
  color: var(--main-primary-color);
}

.responsive-table__head__title {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
}

.responsive-table__body__text::before {
  margin-right: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}

.strategy-table-button-wrap {
  display: flex;
  align-items: center;
  & span {
    color: #fff;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.104px;
    margin-right: 1em;
  }
  & button {
    color: #181818;
    font-size: 1rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.064px;
    border-radius: 55px;
    background: #fea338;
    border: none;
    width: 186px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.container-table {
  min-height: 100vh;
  padding: 2rem;
}
.responsive-table__body__text--name {
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 20px;
  & img {
    cursor: pointer;
  }
}
.responsive-table__body__text--edit {
  & i {
    opacity: 0.25;
    cursor: pointer;
  }
}

.trade-profile {
  padding: 2em;
  .trade-profile-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
    .trade-profile-header-top-dflex {
      display: flex;
      align-items: center;
      gap: 20px;
      & h3 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
      }
      & i {
        opacity: 0.55;
        font-size: 1.2rem;
        color: #acada6;
      }
      & button {
        border-radius: 55px;
        background: #fea338;
        width: auto;
        padding: 0.5em 2em;
        display: flex;
        height: 40px;
        color: #181818;
        font-size: 1rem;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.064px;
        border: none;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .trade-profile-wrap {
    .trade-profile-boxs {
      padding: 1em;
      border-radius: 15px;
      background: #262626;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & h3 {
        color: #b6c233;
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
      }
      & span {
        color: #b6c233;
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 22px;
      }
      & p {
        color: white;
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 22px;
        margin: 0;
      }
    }
  }
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .responsive-table__row th,
  .responsive-table__row td {
    padding: 1rem;
    width: 12em;
  }

  .responsive-table-container {
    width: 100%;
    overflow: auto;
  }
}

.no-data-available-swiper-slides {
  width: 100% !important;
  margin: 0 !important;
  margin-top: 1em !important;
}

.showAnalysis-strategy-dropdown {
  width: 20vw !important;
}

.strategy-version {
  cursor: pointer !important;
}

.analysis-select-dropDown {
  width: 20vw;
  margin-bottom: 3em;
}

.select-content-drop {
  background-color: #262626 !important;
}
#headerConfirmCancelButtons {
  height: 20%;
  width: 100%;
  display: flex;
  background-color: #232323;
}

#headerConfirmCancelButtons button {
  width: 50%;
  background-color: transparent !important;
  color: var(--main-primary-color);
  border: 1px solid var(--main-primary-color);
  border-radius: 15px;
  opacity: 0.8;
}
#headerConfirmCancelButtons button:hover {
  border: 2px solid var(--main-primary-color);
  opacity: 1;
}

.web-edit-strategy-icon-btn {
  color: var(--main-primary-color) !important;
  text-decoration: underline;
}
.edit-down-up-strategy-table-btn {
  display: flex;
  align-items: center;
}

.strategy-card-tag-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .responsive-table {
    font-size: 12px;
  }
  .analysis-select-dropDown {
    width: 27vw;
  }
}

.no-strategy-icon {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.no-data-stratehy-slides {
  & h1 {
    font-size: 2rem;
    color: var(--main-primary-color);
    font-weight: 600;
  }
  & p {
    font-size: 1.3rem;
    font-weight: 400;
    opacity: 0.6;
    max-width: 450px;
  }
}

.strategy-pageheading {
  gap: 1em;
  height: 100%;
  width: 100%;
  border-top: 3px solid var(--light-bg-color);
  position: relative;
  padding-bottom: 2em;
}
