.exitprotocolhistorytablebuttons {
  background-color: var(--main-primary-color) !important;
  color: var(--main-bg-color) !important;
  font-size: 1rem;
  font-weight: 700 !important;
  border-radius: 55px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2em;
}
.exitprotohistorytableheading {
  color: var(--comman-white-color);

  font-size: 2rem;

  font-weight: 600;
}
.history-table-head {
  background-color: var(--history-table-head-bg);
  & th {
    color: var(--main-primary-color);
  }
}

.historytable-daylevelpage {
  cursor: pointer;
  & tr:nth-child(even) {
    background: #262626;
  }
}
.astro-img-bg-box {
  background-color: var(--history-table-head-bg);
  border-radius: 50%;
  height: auto;
  width: 4.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.astro-img-container {
  height: 8vh;
  width: 5vw;
}

.history-table-astro-img {
  width: 100%;
  height: 100%;
}

.instruments-count-number {
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  border-radius: 50%;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
}

.select-bg-pagination {
  background-color: var(--main-bg-color) !important;
  color: var(--comman-white-color) !important;
}
.select-pagination-dropdown {
  color: white !important;
}

.add-modify-drop-down {
  background-color: var(--main-bg-color) !important;
  color: var(--common-white-color) !important;
  font-size: 1rem;
  font-weight: 500;
  width: 400px !important;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.add-modify-drop-down .add-modify-close-btn-head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.4rem;
  font-weight: 600;
}
.add-modifie-column-box-all {
  display: flex;
  justify-content: space-around;
}
.select-all-check-box-strategy {
  font-size: 1rem;
}
.select-all-check-box-strategy .select-all-box {
  background-color: var(--main-primary-color) !important;

  border-color: #fba137 !important;
  background: #fba137 !important;
}

.trade-journal-edit-btn {
  color: var(--main-primary-color);
  text-decoration: underline;

  & button {
    border-color: #fba137 !important ;
  }
}

.history-table-emotion-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.color-yellow-border {
  border-color: #fba137 !important ;
  color: #fba137 !important;
}
