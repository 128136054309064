.boarding-flow-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2em 1em;
  background-image: url("../../../assets/boarding-flow-bg.webp");
  background-size: cover;
  background-position: center;
}

.flow {
  min-height: 70vh;
  border-radius: 25px;
  background: #232323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 2em 4em;
  gap: 2em;
}

.logo-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-direction: column;
}
.logo-text-container h2 {
  color: #fea338;
  text-align: center;

  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 2.1px;
}
.logo-text-container h4 {
  color: #fff;

  text-align: center;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.stepper {
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fea338;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.stepper .step-line {
  height: 1px;
  width: 60px;
  background-color: #fea338;
}
.stepper .step-one {
  background-color: #fea338;
  color: #181818;
}
.instrument {
  color: #fff;
  margin-top: 1em;

  text-align: center;

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 1.3px;
  margin-bottom: 1em;
}

.you-can-change {
  color: rgba(255, 255, 255, 0.55);

  text-align: center;

  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 1.3px;
}
.btn-middle-text {
  color: #fff;

  text-align: center;

  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 1.3px;
}
.stepper #steptwo {
  background-color: #fea338;
  color: #181818;
}
.stepper #stepthree {
  background-color: #fea338;
  color: #181818;
}

.inputs-limit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

#two .text-logo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em 0.5em;
}
#two .inputs-limit-wrapper h6 {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  opacity: 0.75;
}
.inputs-limit-wrapper .risk-profit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.inputs-limit-wrapper .info-limit-text {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  padding: 0.3em 1em;
}
.info-limit-text .day-week-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.inputs-limit-wrapper .all-inputs-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.inputs-limit-wrapper .all-inputs-box {
  width: 559px;
  height: 66px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #2b2b2b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2em 1em;
}
.inputs-limit-wrapper .all-inputs-box .inputs-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7em;
}
.inputs-limit-wrapper .all-inputs-box input {
  text-align: center;
  width: 100px;
  height: 40px;

  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: rgba(254, 163, 56, 0.03);
  color: #fff;
}
#two .inputs-limit-wrapper .divide-line {
  background-color: rgba(255, 255, 255, 0.25);
  width: 430px;
  height: 2px;
}
.trade-all {
  gap: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.big-input-box {
  width: 170px !important;
}
#three {
  padding: 3em 0em;
  gap: 0;
}
#three .text-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  .logo-text-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
}
#three .book-logo-txt h2 {
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
  line-height: 6px;
}
#three .text-logo-wrapper h6 {
  color: #fea338;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
}
#three .main-image-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
#three .text-left-container {
  width: 450px;
  .text-left-one {
    display: flex;
    flex-direction: column;
    text-align: left;

    margin-bottom: 0.5em;
    padding-left: 4em;
  }
}
#three .text-left-container h1 {
  color: #fff;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 700;
  line-height: 2em;
}
#three .text-left-container p {
  color: rgba(255, 255, 255, 0.55);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

#four {
  text-align: left;
  .create-playbook-text {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1em;
  }
  .play-book-headings {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .text-logo-wrapper {
    width: 100%;
    align-items: flex-start;
    .logo-text-container {
      align-items: flex-start;
    }
  }
  .stepper {
    justify-content: flex-start;
  }
  .inputs-limit-wrapper {
    gap: 1em;
    align-items: flex-start;
    width: 100%;

    .playbook-inputs {
      display: flex;
      height: 50px;
      padding: 8px 32px;
      align-items: flex-start;

      width: 100%;
      border-radius: 4px;
      border: 0.5px solid rgba(255, 255, 255, 0.25);
      background: rgba(254, 163, 56, 0.03);
    }
    .playbook-text-area {
      height: 90px;
    }
  }
  .play-book-footer {
    display: flex;
    flex-direction: row;
    text-align: left;
  }
  .play-book-criteria {
    text-align: left;
    width: 100%;
    padding: 1em 2em;
    height: auto;
    border-radius: 15px;
    background: rgba(66, 66, 66, 0.12);

    .playbook-inputs {
      display: flex;
      height: 50px;
      width: 430px;
      padding: 8px 32px;
      align-items: flex-start;
      border-radius: 4px;
      border: 0.5px solid rgba(255, 255, 255, 0.25);
      background: rgba(254, 163, 56, 0.03);
    }
    .criteria {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;
    }
  }
}
.play-book-criteria h1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.072px;
}
.criteria-lable {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.064px;
  margin-left: 3em;
  margin-top: 1em;
}
@media screen and (max-width: 1440px) {
  #three .text-left-container h1 {
    line-height: 1.3em;
    font-size: 1.6em;
  }
  #three .text-left-container p {
    line-height: 1.2em;
    font-size: 15px;
  }
}

.boarding-broker-name-select {
  width: 170px;
  text-align: center;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: rgba(254, 163, 56, 0.03);
  color: #fff;
}

.boarding-broker-name-select-option {
  width: 170px !important;
  text-align: center;

  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: rgba(33, 27, 18, 0.951) !important;
  color: #fff;
}

.boarding-scrip-select {
  width: 170px !important;
  text-align: center;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: rgba(43, 38, 30, 0.951) !important;

  color: #fff;
}
.error-message-red {
  color: red;
  height: 24px;
  display: flex;
}

.borading-next-button {
  width: 227.85px;
  height: 49px;
  flex-shrink: 0;
  background-color: var(--main-primary-color) !important;
  color: var(--main-bg-color);
  text-align: center;
  border-radius: 55px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.onboarding-preview-btn {
  background-color: var(--front-circleBg-color) !important;
  color: white;
}
.create-strategy-onboarding-btn {
  height: 49px !important;
  border-radius: 55px !important;
  color: var(--main-bg-color) !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}
.borading-next-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.onboarding-modal-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#two .inputs-limit-wrapper {
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  justify-content: flex-start;
  padding-right: 0.5em;
  margin-bottom: 1em;
  gap: 1em;
}
#two .inputs-limit-wrapper .all-inputs-wrapper {
  gap: 0;
}

.onboarding-swiper-welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
  background-color: #262626;
  border-radius: 25px;
  padding: 3em 1em;
}

.onboarding-slide-line-two img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.onboarding-slide-two-line-two img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.onboarding-slide-three-line-two img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.onboarding-slide-subheading {
  font-size: 1.3rem;

  line-height: 1.1;
  font-weight: 300;
}
.onboarding-inner-content {
  gap: 1rem;
}
.onbording-head-yellow {
  color: var(--main-primary-color);
}

.onboarding-slide-bottom-text {
  margin-top: 1em;
  font-size: 1.5rem;
  opacity: 0.55;
  font-weight: 300;
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
}

.onboarding-slide-mainheading-quick {
  font-size: 1.7rem;
  font-weight: 500;
}

.quick-tool-tip-btn {
  color: var(--main-primary-color);
  border-bottom: 1px solid #fea338;
}
.makesure-text-slides {
  color: var(--main-primary-color);
  font-size: 1.6rem;
  font-weight: 400;
}
.wizr-wing-onbording {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.wizr-wing-onbording img {
  height: 300px !important;
}

.wizr-wing-onbording .wizer-score-title-data-wrap .enjoy-wizr-wings {
  font-size: 3rem;
}

.wizr-wing-onbording .onbording-enjoy-wing-text {
  top: 7.8em;
  right: 19em;
}

.enjoy-logo-bottom-text {
  color: #fea338;
  font-size: 2rem;
  font-weight: 600;
}

@media only screen and (max-height: 800px) {
  .onboarding-mySwiper .swiper-wrapper {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1300px) {
  /* .savvy-form-boardingflow {
    width: 70%;
  } */
  .flow-one {
    width: 70%;
  }
}

/* @media screen and (max-width: 1800px) {
  .savvy-form-boardingflow {
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
    justify-content: flex-start;
  }
  #two .inputs-limit-wrapper {
    height: auto;
  }
} */

@media screen and (max-width: 1800px) {
  .flow-one {
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
    justify-content: flex-start;
  }
  .flow-two {
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
    justify-content: flex-start;
  }
  #two .inputs-limit-wrapper {
    height: auto;
  }
  #three {
    max-height: 70vh;
    max-width: 80%;
    overflow-y: scroll;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1500px) {
  #three .flow-three {
    width: 90%;
    gap: 1em;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
  .onboarding-swiper-welcome {
    width: 60%;
    height: 90vh;
    padding: 1em;
  }

  .onboarding-slide-line-two {
    width: 75% !important;
    margin: 1rem !important;
    margin-right: 3rem !important;
  }
  .onboarding-slide-bottom-text {
    font-size: 1rem;
  }
  .onboarding-slide-subheading {
    width: 80% !important;
  }
  .onboarding-inner-content {
    gap: 15px;
  }

  .journaling-third-boarding-top {
    gap: 1px !important;
  }
  .journaling-text {
    width: 100% !important;
  }
  .makesure-text-slides {
    font-size: 1rem !important;
  }
  .exitproto-image-boarding {
    margin-left: 4em !important;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .onboarding-swiper-welcome {
    width: 60%;
    height: 91vh;
    padding: 0em 1em;
  }

  .onboarding-slide-line-two {
    width: 55% !important;
    margin: 1rem !important;
    margin-right: 3rem !important;
  }
  .onboarding-slide-bottom-text {
    font-size: 1rem;
    margin: 0;
  }
  .onboarding-slide-subheading {
    width: 80% !important;
    font-size: 1rem;
  }
  .onboarding-inner-content {
    gap: 1px;
  }
  .realtime-widget-img-boarding {
    gap: 1px !important;
  }
  .journaling-third-boarding-top {
    gap: 1px !important;
  }
  .journaling-text {
    width: 100% !important;
  }
  .makesure-text-slides {
    font-size: 1rem !important;
  }
  .exitproto-image-boarding {
    margin-left: 4em !important;
  }
  .second-last-boarding {
    gap: 1px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .onboarding-swiper-welcome {
    width: 60%;
    height: 91vh;
    padding: 0em 1em;
  }
  .onboarding-inner-content {
    gap: 1px;
  }
  .onboarding-slide-line-two {
    width: 55% !important;
    margin: 1rem !important;
    margin-right: 3rem !important;
  }
  .onboarding-slide-bottom-text {
    font-size: 1rem;
    margin: 0;
  }
  .onboarding-slide-subheading {
    width: 80% !important;
    font-size: 0.8rem;
  }
  .borading-next-button {
    width: 180px;
    height: 35px;
    font-size: 14px;
  }
  .realtime-widget-img-boarding {
    gap: 1px !important;
  }
  .journaling-third-boarding-top {
    gap: 1px !important;
  }
  .journaling-text {
    width: 100% !important;
  }
  .makesure-text-slides {
    font-size: 1rem !important;
  }
  .exitproto-image-boarding {
    margin-left: 4em !important;
  }
  .second-last-boarding {
    gap: 1px !important;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
  .wizr-wing-onbording img {
    height: 200px !important;
  }
  .gap-for-last-screen {
    gap: 0px !important;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .wizr-wing-onbording {
    margin: 0.5rem !important;
    & img {
      height: 200px !important;
    }
  }
  .gap-for-last-screen {
    gap: 0px !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .wizr-wing-onbording {
    margin: 0.5rem !important;
    & img {
      height: 150px !important;
    }
  }
  .gap-for-last-screen {
    gap: 0px !important;
  }
  .last-screen-button {
    margin-top: 0;
  }
  .how-start-your-text {
    margin: 0 !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .wizr-wing-onbording {
    margin: 0.5rem !important;
    & img {
      height: 100px !important;
    }
  }
  .gap-for-last-screen {
    gap: 0px !important;
  }
  .last-screen-button {
    margin-top: 0;
  }
  .how-start-your-text {
    margin: 0 !important;
  }
}

.percent-inside-input {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  font-size: 18px;
}

.rupees-inside-input {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  font-size: 18px;
}

.padding-left-input {
  padding-left: 30px;
}

.padding-right-input {
  padding-right: 35px;
}

.padding-right-big-input {
  padding-right: 30px;
}

.padding-left-big-input {
  padding-left: 30px;
}
