.log-in {
  background-color: var(--main-bg-color);
  width: 100%;
  min-height: 100vh;
  color: var(--main-primary-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 6em; */
  padding: 2em 0em;
}
.log-in button:hover {
  cursor: pointer !important;
}
.log-in-left {
  border-radius: 0px 25px 25px 0px;
  background: var(--light-bg-color);
  width: 50%;
  height: 98%;

  flex-direction: column;

  gap: 1em;
  padding: 2em 0em;
  flex-shrink: 0;
}
.log-in span {
  color: var(--common-white-color);
}
.log-in .lable {
  color: var(--common-white-color);
  opacity: 0.35;
  margin-top: 1em;
  margin-bottom: 0.2em;
}
.log-in p {
  color: var(--login-page-peragraph-color);

  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 1.1px;
  width: auto;
}
.log-in h2 {
  color: var(--main-primary-color);
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 280px;
  text-align: center;
}
.log-in h1 {
  color: var(--main-primary-color);

  font-size: 2.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 2.1px;
  text-align: center;
}
.log-in h4 {
  color: var(--main-primary-color);

  text-align: center;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 1.2px;
}
.log-in .logo-text-container {
  gap: 0.5em;
  flex-direction: row;
}
.log-in .reg-log-button {
  border-radius: 55px;
  background: var(--main-primary-color);
  width: 227.85px;
  height: 49px;
  /* flex-shrink: 0; */
  font-weight: 700;
  font-size: 16px;
  color: var(--main-bg-color);
}

.log-in .log-in-right {
  flex-direction: column;

  gap: 2em;
  width: 50%;
}
.log-in-right input {
  display: flex;
  width: 349px;
  height: 49px;
  padding: 8px 32px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--new-password-input-bg);
  color: var(--main-primary-color);
}
.log-in-right .google-button {
  display: inline-flex;
  padding: 12px 25px;
  align-items: center;
  gap: 12px;

  border-radius: 12px;
  border: 1px solid #fea338;

  color: var(--main-primary-color);

  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}

.log-in-right .create-forget-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}
.log-in-right .create-forget-btn button {
  color: var(--main-primary-color);
  text-align: center;
  background: none;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-decoration-line: underline;
}
.log-in-right .create-forget-btn .create-account-btn {
  font-size: 22px;

  font-weight: 700;
  line-height: 19px;
}
.right-content-logo {
  margin-bottom: 2em;
}
.logo-text-container p {
  margin-bottom: 0%;
}
.logo-text-container h5 {
  margin-bottom: 0%;
  font-weight: 700;
}

.google-signIn-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0em;
}
.logIn-form-containter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5em;
}

@media screen and (min-width: 900px) and (max-width: 1440px) {
  .log-in-left {
    gap: 0.1em;
  }
}

.log-in-right-content-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.log-in-right-content .loginPage-input-error {
  font-size: 14px;
  line-height: 1;
  margin-top: 0.3em;
  color: red;
}
.log-in-right-content .password-login {
  position: relative;
}
.log-in-right-content .login-password-Iicon {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-height: 900px) {
  .log-in-right-content .right-content-logo {
    height: 16vh;
  }
}
