.web-strategy-modal-container-main {
  padding: 2em !important;
  background-color: var(--light-bg-color);
  border: none !important;
  height: 700px;
  overflow-y: scroll;
}

.web-createStrategi-wrppper {
  text-align: left;
  height: "720px";
  width: "720px";
  display: flex;
  overflow-x: "hidden";
  overflow-y: "scroll";
  box-sizing: border-box;
  justify-content: center;
  font-family: "Source Sans 3", sans-serif;
  color: var(--common-white-color);

  .web-createStrategi-content-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .web-create-playbook-text {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--common-white-color);
    .web-strategy-yellow-heading {
      color: var(--main-primary-color);
    }
  }
  .web-play-book-headings {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .web-text-logo-wrapper {
    width: 100%;
    align-items: flex-start;
    margin-bottom: 5px;
    .web-logo-text-container {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .stepper {
    justify-content: flex-start;
  }
  .web-inputs-limit-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 100%;

    .web-playbook-inputs {
      display: flex;
      height: 50px;
      padding: 8px 32px;
      align-items: flex-start;

      width: 100%;
      border-radius: 4px;
      border: 0.5px solid rgba(255, 255, 255, 0.25);
      background: var(--dropdown-field-bg-color);
    }
    .web-playbook-text-area {
      height: 90px;
    }
  }
  .web-strategy-book-footer {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin: 0;
    gap: 25px;
    border-top: none !important;
  }
  .web-play-book-criteria {
    text-align: left;
    width: 100%;
    padding: 15px 30px 30px 15px;
    /* height: 350px; */
    /* overflow-y: scroll; */
    border-radius: 15px;
    background: rgba(66, 66, 66, 0.12);

    .web-playbook-inputs {
      display: flex;
      height: 50px;
      width: 430px;
      padding: 8px 32px;
      align-items: flex-start;
      border-radius: 4px;
      border: 0.5px solid rgba(255, 255, 255, 0.25);
      background: var(--dropdown-field-bg-color);
    }
    .web-criteria {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;
    }
  }
}

.web-play-book-criteria h1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.072px;
}
.web-criteria-lable {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.064px;
  margin-left: 3em;
  margin-top: 1em;
}

.web-six-dot-img {
  width: 20px;
  height: 28px;
}
.web-six-dot-img > img {
  width: 100%;
  height: 100%;
}
.web-criteria-input-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.web-entry-exit-criteria-all-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.web-createStrategi-wrppper .web-entry-exit-btn-plus {
  min-width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;

  background: var(--main-primary-color) !important;
  color: var(--main-bg-color);
  font-size: 22px;
  font-weight: 600;
}
.web-createStrategi-wrppper .web-entry-exit-btn-minus {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-primary-color);
  font-size: 1rem;
  font-weight: 600;
}
.web-createStrategi-wrppper .web-entry-exit-btn-plus:hover {
  background: var(--hover-button-color);
}

.web-createStrategi-wrppper .web-cancel-strategy-button {
  width: 186px;
  height: 40px;
  border-radius: 55px;
  background: #2f2f2f;
  text-align: center;
  color: var(--common-white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  padding: 0;
  letter-spacing: 0.064px;
}

.web-createStrategi-wrppper .web-create-strategy-button {
  border-radius: 55px;
  background: var(--main-primary-color);
  width: 186px;
  height: 40px;

  margin: 0;
  color: var(--main-bg-color);

  text-align: center;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;

  letter-spacing: 0.064px;
}
.web-createStrategi-wrppper .web-name-your-trade-peragraph {
  width: 54%;
  font-weight: 300;
}

.web-criteria-input-entry-exit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.web-currentNotesStrategy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  justify-content: flex-start;
  padding-left: 55px;
  margin-top: 15px;
}
.web-currentNotesPeraNumber .web-currentNotesPera {
  font-size: 16px;
  line-height: 20px;
}
.web-criteria-inputs .web-currentNotesPeraNumber {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px;
  gap: 10px;
  padding: 0px;
}
.web-criteria-inputs .web-currents-notes-wrapper-num-remove {
  display: flex;
  max-width: 430px;
  width: auto;
  height: fit-content;
  align-items: center;
  border-radius: 50px;
  padding: 10px 20px;
  gap: 10px;
  background: linear-gradient(
    rgba(254, 163, 56, 0.08) 0%,
    rgba(202, 85, 59, 0.08) 100%
  );
}
.web-criteria-inputs .web-currentNotesPeraNumber .web-currentNotesIndex {
  display: flex;
  width: 22px;
  height: 22px;
  color: var(--main-bg-color);
  background-color: var(--main-primary-color);
  font-weight: 800;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 55px;
  padding: 15px;
}

.web-create-strategy-modal-btn {
  background-color: var(--main-primary-color) !important;
}

.web-edit-strategy-icon-btn {
  background: transparent !important;
}

@media only screen and (max-height: 900px) {
  .web-strategy-modal-container-main {
    max-height: 70% !important;
    overflow-y: scroll;
  }
}
