.tradeCalender {
  width: 100%;
  padding: 2em 2em 1em 2em;
  background-color: #181818;
  color: #fff;
  flex-direction: column;
  gap: 3em;
  min-height: 100vh;
  overflow-y: hidden;
}

.yourCalender {
  gap: 1em;
  height: 100%;
  width: 100%;
  border-top: 3px solid var(--main-primary-color);
  position: relative;
  padding-bottom: 1em;
}
.calenderLogoText {
  position: absolute;
  background-color: var(--main-bg-color);
  padding: 1em;
}

.calenderLogoText h1 {
  color: var(--main-primary-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
}
.line {
  height: 2px;
  background-color: var(--main-primary-color);
  width: 630px;
  opacity: 0.55;
}
.tradeDates {
  display: flex;
  width: 100%;
  gap: 6em;
}
.tradeDatesLeft {
  width: 70%;
}
.tradeDatesRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.5em;
  width: 30%;
}
.rightOne {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.dateRightOne {
  display: flex;
  gap: 1em;
}
.dateRightTwo {
  display: flex;
  gap: 1em;
}
.roi {
  border-radius: 25px;
  width: 100%;
  height: 13vh;

  flex-direction: column;

  background: linear-gradient(
    180deg,
    rgba(254, 163, 56, 0.08) 0%,
    rgba(202, 85, 59, 0.08) 100%
  );
}
.roi > h3 {
  color: var(--main-primary-color);

  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.roi > h2 {
  color: #fff;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.rightTwo {
  display: flex;
  flex-direction: column;
  gap: 3em;
}
.dateRightThree,
.dateRightFour {
  display: flex;
  align-items: center;

  gap: 1em;
}
.winDays,
.lossDays {
  width: 100%;
  height: 13vh;

  flex-direction: column;

  border-radius: 25px;
  position: relative;
}
.winDays {
  background-color: #1b221c;
  border: 1px solid #24462a;
}
.lossDays {
  background-color: #38312e;
  border: 1px solid #a05250;
}

.rightThree {
  border-radius: 25px;
  border: 1px solid #373737;
  background-color: var(--secondary-bg-color);
}

.upmeter {
  gap: 0.4em;
  position: absolute;
  top: 0.7em;
  right: 0.5em;
}
.upmeter h3 {
  color: #9ff975;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.winupmeter h3 {
  color: #9ff975;
}
.lossupmeter h3 {
  color: #ff5858;
}
.winlossIndicator {
  position: absolute;
  left: 50%;
  top: -5%;
  transform: translate(-50%, -50%);
}

.winlossDynamicdata {
  text-align: center;
  margin-top: 1em;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  display: none;
}
/* .fc-h-event {
  border: 1px solid rgba(68, 188, 89, 0.55);
  background: rgba(68, 219, 96, 0.06);
} */

.diamond {
  border: 3px solid rgba(68, 188, 89, 0.55);
  background: rgba(68, 219, 96, 0.06);
}
.fc .fc-button-primary {
  border-radius: 25px;
  font-weight: 700;
  background-color: #684923;
  color: #181818;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--main-primary-color);
  color: #181818;
  border: none;
}
.fc-All-button,
.fc-Wins-button,
.fc-Loses-button {
  width: 6vw;
}
.fc .fc-button-group .fc-prev-button {
  background-color: transparent !important;
  color: var(--common-white-color);
  width: 70px;
  opacity: 0.7;
}
.fc .fc-button-group .fc-next-button {
  background-color: transparent !important;
  color: var(--common-white-color);
  width: 70px;
  opacity: 0.7;
}
.fc-prev-button:hover {
  opacity: 1 !important;
  color: var(--common-white-color) !important;
}

.fc-next-button:hover {
  opacity: 1 !important;
  color: var(--common-white-color) !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
  width: 100px;
}

.eventimage {
  width: 2.5em;
}
.fc-theme-standard td,
.fc-theme-standard th,
.fc-theme-standard .fc-scrollgrid {
  border: none;
  padding: 2px;
}
#trade-calender .tradeDatesLeft td {
  padding: 5px !important;
}

.fc .fc-daygrid-day-frame {
  border-radius: 15px;
  background: rgba(103, 103, 103, 0.06);
  color: var(--common-white-color);
  overflow: hidden;
  height: 115px;
}

.fc .fc-daygrid-day-number {
  color: var(--common-white-color);
  text-decoration: none;
}
.fc .fc-col-header-cell-cushion {
  color: var(--common-white-color);
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.35;
  text-decoration: none;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  border-radius: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 118px;
}
.fc-event-main {
  & p,
  & span {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    line-height: 26px;
  }
}
.isolationMoon {
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(103, 103, 103, 0.06);
  cursor: not-allowed !important;
}
.dimond {
  border: 2px solid #50e36a;
  background: rgba(68, 188, 89, 0.06);
  & p {
    color: #44bc59;
  }
}
.dollar {
  border: 1px solid rgba(68, 188, 89, 0.55);
  background: rgba(68, 188, 89, 0.06);
  & p {
    color: #44bc59;
  }
}

.dollarBurn {
  border: 1px solid #bd806c;
  background: rgba(189, 128, 108, 0.12);
  & p {
    color: #ff5858;
  }
}
.bombicon {
  border: 3px solid rgba(248, 78, 78, 0.75);
  background: rgba(255, 0, 0, 0.08);
  & p {
    color: #ff4747;
  }
}
.fc .fc-daygrid-day-top {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.fc .fc-daygrid-day.fc-day-today {
  border-radius: 15px;
  background-color: transparent;
}
.fc .fc-day-today .fc-daygrid-day-frame {
  background-color: #e39439b3;
}

.fc-scroller::-webkit-scrollbar {
  display: none;
}
.fc,
.fc *,
.fc ::after,
.fc ::before {
  text-align: -webkit-center;
}

.fc .fc-button {
  border: none;
  box-shadow: none !important;
}
.fc .fc-button-primary:hover {
  border: none;
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  border-color: transparent;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

@media screen and (min-width: 800px) and (max-width: 1400px) {
  .tradeDates {
    gap: 7em;
    flex-direction: column;
  }
  .tradeDatesLeft {
    width: 100%;
    min-height: 100vh;
  }
  .tradeDatesRight {
    width: 100%;
  }
  .roi {
    width: 50%;
  }
  .winDays,
  .lossDays {
    width: 50%;
    height: 14vh;
  }
  .imgPer {
    gap: 0em;
    height: 60%;

    margin-top: -4.5em;
  }
  .rightThree {
    width: 100%;
  }
  .dateRightFive,
  .dateRightSix,
  .dateRightSeven {
    width: 100%;
  }
  .dateRightFive {
    height: auto;
  }

  .fc .fc-view-harness-active > .fc-view {
    min-height: 100vh;
  }
  .calenderLogoText h1 {
    font-size: 20px;
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 118px;
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--medium-bg-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
}
.chart-replay-section {
  display: grid;
  gap: 20px;
  .chart-replay-header-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    .hover\:text-accent-foreground:hover {
      color: gray;
    }
    .chart-replay-date-selector {
      border-color: var(--main-primary-color) !important;
      color: var(--common-white-color);
      width: fit-content;
    }
    .chart-replay-header-right {
      display: flex;
      align-items: center;
      overflow-x: auto;
      gap: 10px;
      overflow-y: hidden;

      .chart-replay-header-right-item:hover .btn-left-section-wrap,
      .chart-replay-header-right-item:hover .btn-right-section {
        border: 3px solid #b6c233;
        box-shadow: 0px 0px 10px 0px rgba(182, 194, 51, 0.45);
      }
      .chart-replay-header-right-item {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 127px;
        height: 55px;
        cursor: pointer;
        .btn-left-section-wrap {
          border: 3px solid var(--light-bg-color);
          background-color: var(--light-bg-color);
          padding: 2px;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 55px;
        }
        .btn-left-section {
          border-radius: 55px;
          background: var(--main-primary-color);
          color: #171717;
          font-size: 22px;
          font-weight: 600;
          width: 60.303px;
          height: 45.816px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .btn-right-section {
          border: 3px solid var(--light-bg-color);
          border-radius: 25px;
          background: var(--light-bg-color);
          color: var(--main-primary-color);
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          width: 76.439px;
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .graph-data-box-wrapper {
    display: grid;
    grid-template-columns: 1fr 450px;
    gap: 20px;
    .chart-bottom-btn {
      border-radius: 55px;
      background: var(--main-primary-color);
      color: var(--main-bg-color);
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      padding: 0.5em 1em;
    }
    .data-box {
      display: grid;
      gap: 10px;
      .data-box-item-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
      .data-box-item,
      .data-box-item-gridItem,
      .data-box-item-scroll,
      .scroller-item {
        background: linear-gradient(
          180deg,
          rgba(254, 163, 56, 0.08) 0%,
          rgba(202, 85, 59, 0.08) 100%
        );
        border-radius: 20px;
        text-align: center;
        padding: 1em;
        & span {
          color: var(--main-primary-color);
          font-size: 16px;
          font-weight: 400;
        }
        & h4 {
          color: var(--common-white-color);
          font-size: 22px;
          font-weight: 600;
        }
      }
      .data-box-item-scroll {
        max-height: 380px;
        .reflection-notes {
          color: var(--main-primary-color);
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 0.5em;
          display: block;
        }
        .scroll-section {
          max-height: 75%;
          overflow: auto;
        }
        .scroller-item {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 0.5em;

          & p {
            width: 85%;
            text-align: left;
          }
          .reflection-notes-no-div {
            background: var(--main-primary-color);
            width: 2em;
            height: 2em;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .reflection-notes-no {
              color: var(--common-black-color);
            }
          }
        }
      }

      .input-plus-add-wrap {
        margin-top: 1em;
        & button {
          background: var(--main-primary-color);
          border: none !important;
        }
      }
    }
  }
}

.daylevel-analysis-calendar-tooltip {
  position: fixed;
  width: 11vw;
  /* top: tooltipData.y,
  left: tooltipData.targetElemWidth / 2 + tooltipData.x, */
  padding: 1rem 0.5rem;
  background-color: #28282b;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -110%);
  z-index: 1;
}
.rightThree .co-pilot-insight-section .co-pilot-scroll-div {
  height: 14em;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.calen-win-loss-all-btn {
  position: absolute;
  right: -8%;
  transform: translate(-50%, -0%);
  display: flex;
  background-color: #34291c;
  color: var(--common-white-color);
  border-radius: 100px;
  & button {
    background-color: #34291c;
    outline: none !important;
    border: none !important;
  }
}
.candle-chart-min-button-container {
  display: flex;
  gap: 0.5em;
  & button {
    background-color: var(--main-primary-color) !important;
    color: var(--main-bg-color) !important;
  }
}

.trade-chart-to-be-shown {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (min-width: 800px) and (max-width: 1400px) {
  .chart-replay-section .graph-data-box-wrapper {
    grid-template-columns: 1fr;
  }
}

.fc .fc-toolbar {
  justify-content: flex-start;
}

@media only screen and (min-width: 1930px) and (max-width: 2020px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 120px;
  }
}
@media only screen and (min-width: 2021px) and (max-width: 2100px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 130px;
  }
}

@media only screen and (min-width: 2101px) and (max-width: 2250px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 140px;
  }
}
@media only screen and (min-width: 2251px) and (max-width: 2300px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 148px;
  }
}
@media only screen and (min-width: 2301px) and (max-width: 2350px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 153px;
  }
}

@media only screen and (min-width: 2351px) and (max-width: 2400px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 158px;
  }
}

@media only screen and (min-width: 2401px) and (max-width: 2450px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 163px;
  }
}

@media only screen and (min-width: 2451px) and (max-width: 2500px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 168px;
  }
}

@media only screen and (min-width: 2501px) and (max-width: 2550px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 173px;
  }
}
@media only screen and (min-width: 2551px) and (max-width: 2600px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 178px;
  }
}

@media only screen and (min-width: 2601px) and (max-width: 2650px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 183px;
  }
}
@media only screen and (min-width: 2651px) and (max-width: 2700px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 188px;
  }
}
@media only screen and (min-width: 2701px) and (max-width: 2750px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 193px;
  }
}

@media only screen and (min-width: 2751px) and (max-width: 2800px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 198px;
  }
}

@media only screen and (min-width: 2801px) and (max-width: 2850px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 203px;
  }
}
@media only screen and (min-width: 2851px) and (max-width: 2900px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 208px;
  }
}
@media only screen and (min-width: 2901px) and (max-width: 2950px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 213px;
  }
}
@media only screen and (min-width: 2951px) and (max-width: 3000px) {
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    height: 218px;
  }
}

.trade-log-manually {
  cursor: not-allowed !important;
  opacity: 0.5;
}

@media only screen and (max-height: 800px) {
  .roi {
    height: 25vh;
  }
  .winDays,
  .lossDays {
    height: 25vh;
  }
}

.entry-rule-chart-box {
  display: flex;
  gap: 1em;
  width: 100%;

  & span {
    width: 20%;
  }
}

.sortbyorder-btn-icon {
  color: var(--main-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2em;
  font-size: 1rem;
  margin-top: 0.5em;

  & img {
    width: 0.8em;
    height: 0.8em;
  }
}

.select-and-sort {
  width: 220px;
}
.tradeDatesRight .loading-msg-content {
  height: auto;
}
.daylevel-roi-error-cont {
  height: 122%;
}

.tradeCalender .fc-icon-chevron-right::before {
  content: ">" !important;
}
.tradeCalender .fc-icon-chevron-left::before {
  content: "<" !important;
}

.calender-page-header-tooltip {
  z-index: 222312313131313 !important;
  font-size: 16px !important;
  line-height: 1 !important;
  opacity: 1 !important;
}
