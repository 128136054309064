.reset-password-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url("../../../assets/boarding-flow-bg.webp"); */
  background-size: cover;
}

.send-otp-reset-password-lable {
  font-size: 16px;
  color: var(--common-white-color);
  opacity: 0.7;
  margin-bottom: 3px;
  margin-left: 5px;
}
.reset-password-otp-input {
  padding: 8px 10px;
  width: 350px;
  align-items: flex-start;
  background: var(--new-password-input-bg);
  border: 0.5px solid #ffffff40;
  border-radius: 4px;
  color: var(--main-primary-color);
}
.reset-change-password-form-container {
  background-color: var(--light-bg-color);
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 4em 4em;
  border-radius: 15px;
}

.button-max-width {
  max-width: 200px;
}

.current-new-password-error-msg {
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.2;
  width: 350px;
  color: var(--common-red-color);
  opacity: 1;
}

.otp-number-inputfor-reset {
  border-bottom: 2px solid var(--main-primary-color);
  width: 40px;
  font-size: 30px;
  color: var(--main-primary-color);
  outline: none;
  text-align: center;
  background-color: transparent;
}

@media screen and (max-height: 700px) {
  .height-reset-form {
    height: 450px;
    overflow: hidden;
  }

  .change-pass-input-lable-container {
    overflow: hidden;
    overflow-y: scroll;
  }
}
