@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html,
body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: "Source Sans 3", sans-serif;
  background-color: var(--main-bg-color);
  overflow-x: hidden;
}
body[data-scroll-locked] {
  min-width: 100%;
}
:root {
  --main-primary-color: #fba137;
  --green-color: #44ae20;
  --green-box-bg: #3a672b98;
  --main-bg-color: #181818;
  --secondary-bg-color: #1c1c1c;
  --light-bg-color: #262626;
  --medium-bg-color: #232323;
  --common-white-color: #ffffff;
  --common-black-color: #000;
  --all-input-box-bg: #2b2b2b;
  --scrip-option-trade: rgb(37, 36, 36);
  --new-password-input-bg: rgba(254, 163, 56, 0.03);
  --login-page-peragraph-color: rgba(255, 255, 255, 0.55);
  --front-circleBg-color: #434046;
  --water-level-bg-big-circle: #c4853d;
  --water-level-bg-small-3d: rgb(93, 144, 145);
  --water-level-bg-small-circle: cadetblue;
  --water-level-bg-medium-circle: #ff6550;
  --water-level--bg-medium-3d: #d25342;
  --history-table-head-bg: #34291c;
  --hit-exit-btn-bg: #6a4b25;
  --common-red-color: red;
}
.padding-top-main-content {
  padding-top: 110px;
}
.on-hover-bg-date-pik-btn:hover {
  background: rgba(254, 163, 56, 0.03) !important;
  color: var(--common-white-color);
}
.calender-icon-color {
  color: var(--main-primary-color) !important;
}
.dropdown-calender-popup {
  background-color: var(--medium-bg-color) !important;

  color: #fff;
}

.calender-popover {
  border: none !important;
  border-radius: 5px;
}
.on-hover-bg-date-pik-btn:hover {
  background: rgba(254, 163, 56, 0.03) !important;
  color: var(--common-white-color);
}
.calender-icon-color {
  color: var(--main-primary-color) !important;
}
.dropdown-calender-popup {
  background-color: var(--medium-bg-color) !important;
  /* width: 18vw; */
  color: #fff;
}
.calender-popover {
  border: none !important;
  border-radius: 5px;
}
.dashboard-card {
  background-color: var(--secondary-bg-color);
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #373737;
}
.title {
  color: var(--common-white-color);
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1em 1em 0;
  display: flex;
  align-items: center;
  & img {
    margin-right: 0.5em;
  }
  & span {
    color: var(--main-primary-color);
  }
}
.error {
  color: red;
}
.text-center {
  text-align: center;
  justify-content: center;
}
.co-pilot-insight-section {
  padding-bottom: 0.6em;
  border-radius: 25px;
  border: 1px solid #373737;
  background-color: var(--secondary-bg-color);
  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: var(--medium-bg-color);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
  }

  .co-pilot-scroll-div {
    height: 13em;
    overflow: auto;
    padding: 1em;
    margin-right: 1em;

    .co-pilot-scroll-div-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .co-pilot-scroll-div-item {
      gap: 30px;
      background-color: var(--medium-bg-color);
      border-radius: 15px;
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      padding: 1em;
      & p {
        margin: 0;

        color: var(--main-primary-color);
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
.wizer-score {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em;
  position: relative;
  width: 14.5em;
  margin: auto;
  & img {
    position: relative;
  }
  .wizer-score-title-data-wrap {
    position: absolute;
    top: 4.8em;
    right: 5.4em;
    & h3 {
      margin: 0;
      color: var(--main-primary-color);
      text-align: center;
      /* text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.25); */
      font-size: 0.8rem;
      font-style: italic;
      font-weight: 900;
      margin: 0;
      line-height: 6px;
    }
    & span {
      color: var(--main-primary-color);
      text-align: center;
      /* text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.25); */
      font-size: 2rem;
      font-style: italic;
      font-weight: 900;
    }
  }
}
.border-none-for-button {
  border: none !important;
}
.displayFlexCentre {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayFlexCentreColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-div {
  padding: 2em;
  min-height: 100vh;
  .section-content {
    height: 100%;
    border-top: 3px solid var(--light-bg-color);
    position: relative;
    padding: 4em 0;
    .section-content-title {
      background: var(--main-bg-color);
      color: var(--main-primary-color);
      font-weight: 600;
      font-size: 1.2rem;
      position: absolute;
      border-radius: 3em;
      padding: 0.5em 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -2.5em;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

.my-treemap .rv-treemap__leaf {
  display: flex;
  align-items: center;
  justify-content: center;
}
#chart {
  width: 100%;
}

.daypickerSelectDay .bg-primary {
  background-color: var(--main-primary-color) !important;
  color: var(--common-black-color);
}

.cumulative-error-msg {
  background: transparent !important;

  backdrop-filter: blur(4px);
}

.daily-error-msg {
  background: transparent !important;
}
.scrip-map-error-bound {
  background: transparent !important;

  backdrop-filter: blur(4px);
}

.log-out-btn-boardingflow {
  background-color: var(--main-primary-color) !important;
  color: var(--main-bg-color);
  font-weight: 700 !important;
  border-radius: 55px;
  padding: 1em 3em !important;
}
.log-out-btn-boardingflow:hover {
  opacity: 0.8;
}

.sure-to-logout {
  background-color: #181818;
  border: none !important;
  outline: none !important;
  color: var(--common-white-color);
  text-align: center;
  padding: 4em 2em;
  padding-bottom: 2em;
}

.no-yes-btn {
  gap: 40px;
}
.yes-btn {
  background-color: var(--main-primary-color);
  color: var(--main-bg-color);
  padding: 0.4em;
  width: 146px;
  font-weight: 600;
  border-radius: 55px;
}

.yes-btn:hover {
  opacity: 0.8;
}
.no-btn {
  background-color: var(--front-circleBg-color);
  color: var(--common-white-color);
}

.confirm-the-action {
  color: var(--main-primary-color);
}
.mobile-comming-soon-text {
  color: var(--main-primary-color);
  text-align: center;
}

.user-id-used-to-text {
  font-size: 14px;
  opacity: 0.5;
}
