.tutorials-heading {
  color: var(--main-primary-color);
  font-size: 3rem;
  font-weight: 600;
  background-color: var(--main-bg-color);
  width: fit-content;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}

.video-heading {
  color: var(--common-white-color);
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.video-overview {
  color: var(--common-white-color);
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 10px;
  opacity: 0.7;
}
.iframe-video {
  width: 60vw;
  height: 70vh;
}
.video-title-over {
  max-width: 27vw;
}
.demo-video-pageheading {
  gap: 1em;

  width: 100%;
  border-top: 3px solid var(--light-bg-color);
  position: relative;
}
.video-and-title {
  gap: 5rem;
}
@media screen and (min-width: 850px) and (max-width: 1500px) {
  .video-and-title {
    flex-direction: column;
    gap: 2rem;
  }
  .video-title-over {
    max-width: 90vw;
    width: 100%;
  }
  .iframe-video {
    width: 80vw;
  }
}
