.trade-profile {
  padding: 2em;
  /* height: 100vh; */
  background: rgba(24, 24, 24, 0.75);

  .trade-profile-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
    .trade-profile-header-top-dflex {
      display: flex;
      align-items: center;
      gap: 20px;
      & h3 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
      }

      & button {
        border-radius: 55px;

        background: var(--main-primary-color);
        width: auto;
        padding: 0.5em 2em;
        display: flex;
        height: 40px;
        color: #181818;
        font-size: 1rem;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.064px;
        border: none;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .trade-profile-wrap {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    padding-bottom: 20px;
    .trade-profile-boxs {
      padding: 1em;
      border-radius: 15px;
      background: #262626;
      text-align: center;
      & h3 {
        color: #b6c233;
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
        text-transform: capitalize;
      }
      & span {
        color: #b6c233;
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 22px;
      }
      & p {
        color: white;
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 22px;
        margin: 0;
      }
    }
  }
}
.log-out-btn-profile {
  background: var(--main-primary-color) !important;
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .trade-profile
    .trade-profile-header-top
    .trade-profile-header-top-dflex
    .manageEditChange {
    padding: 0.5em 1em;
    line-height: 1;
    width: 15vw;
  }
  .trade-profile .trade-profile-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

.borker-id-edit-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3em;
  margin-bottom: 0.2em;
}
.broker-id-name-profile {
  font-weight: 600;
  color: var(--main-primary-color);
  letter-spacing: 1px;
}

.web-edit-profile-modal-btn {
  background-color: transparent !important;
  color: var(--main-primary-color) !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.scrip-profile-text-red {
  color: #ca553b !important;
}

.flex-auto-row {
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.profile-page-broker-name {
  margin-right: 20px;
}

.manageEditChange {
  cursor: not-allowed !important;
  opacity: 0.5;
}
@media only screen and (max-height: 900px) {
  .trade-profile {
    height: auto;
  }
}

.padding-top-main-content .loading-msg-content {
  height: auto;
}

.support-in-webapp {
  background-color: var(--light-bg-color) !important;
  z-index: 99999 !important;
}

.got-question-text-webapp {
  color: var(--common-white-color);
  text-align: center;
  line-height: 1;
}
.support-email-webapp {
  color: var(--main-primary-color);
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .trade-profile .trade-profile-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
