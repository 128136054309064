.consent-screen-modal {
  background-color: var(--light-bg-color);
  border-radius: 25px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 80%;
  width: 1000px;
  color: var(--common-white-color);
}

.consent-container {
  font-size: 22px;
  line-height: 46px;

  & ul {
    margin-left: 30px;
    list-style-type: circle;

    margin-bottom: 30px;
  }
}

.agree-consent-btn {
  background-color: var(--main-primary-color) !important;
  border-radius: 55px !important;
  color: var(--main-bg-color) !important;
  padding: 5px 60px !important;
  font-weight: 800 !important;
}

@media screen and (max-height: 500px) {
  .consent-screen-modal {
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .imp-info {
    margin-top: 90px;
  }
}
