/* .verify-user-page {
  width: 100%;
  height: 100vh;
  background-color: #181818;
  color: white;
} */

/* .otp-email-input {
  border-bottom: 2px solid #ccc;
  width: 50px;
  height: 60px;
  font-size: 40px;
  text-align: center;
  outline: none;
  color: white;
} */

.verify-user-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #181818;
  color: white;
  background-image: url("../../../assets/boarding-flow-bg.webp");
  background-size: cover;
}

.dialog-content {
  background-color: #232323;
  max-width: 50%;
  width: 35%;
  height: auto;
  padding: 30px 40px;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: center;
}

.otp-input {
  /* Add any additional styles for the OTP input component */
  font-size: 16px; /* Adjust the font size as needed */
}

.varify-email-otp-logo {
  width: 15%;
  height: 15%;
}
.varify-email-otp-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.verify-email-head {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--main-primary-color);
}
.verify-email-second-head {
  font-size: 1.1rem;
  font-weight: 500;
  opacity: 0.8;
}
.email-otp-verify-button {
  margin-top: 20px;
  background-color: var(--main-primary-color) !important;
  color: #181818 !important;
  width: 30%;
  font-size: 1rem !important;
  font-weight: 700 !important;
  border-radius: 55px !important;
  border: 1px solid #181818;
}
.resend-otp-button {
  background-color: #2f2f2f !important;
  color: white !important ;
  /* border: 0.5px solid #fff; */
}

@media only screen and (max-width: 1300px) {
  .dialog-content {
    width: 50%;
  }
}
