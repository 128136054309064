.web-trader-profile-container-main {
  background: var(--medium-bg-color);
  border: none !important;
  height: 800px;
  color: var(--common-white-color);
  overflow-x: hidden;
}

.web-betwizr-trader-profile-inputs {
  border-radius: 25px;
  background: var(--medium-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--common-white-color);

  gap: 15px;
}
.web-traderProfile-form-field {
  width: 600px;
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.web-traderProfile-form-field .web-inputs-limit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.web-text-logo-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 10px;
}

.web-inputs-limit-wrapper h6 {
  color: var(--common-white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  opacity: 0.75;
}

.web-traderProfile-form-field .web-inputs-limit-wrapper .web-risk-profit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.web-traderProfile-form-field .web-inputs-limit-wrapper .web-info-limit-text {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  padding: 0.3em 1em;
}
.web-info-limit-text .web-day-week-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
}
.web-daily-heding-marging-right {
  margin-right: 35px;
}

.web-traderProfile-form-field
  .web-inputs-limit-wrapper
  .web-all-inputs-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.web-traderProfile-form-field .web-inputs-limit-wrapper .web-all-inputs-box {
  width: 550px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--all-input-box-bg);
  color: var(--common-white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2em 1em;
}

.web-traderProfile-form-field
  .web-inputs-limit-wrapper
  .web-all-inputs-box
  .web-inputs-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.web-traderProfile-form-field .web-all-inputs-box input {
  text-align: center;
  width: 100px;
  height: 35px;
  padding: 5px;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--dropdown-field-bg-color);
  color: var(--common-white-color);
}
.web-traderProfile-form-field
  .web-inputs-limit-wrapper
  .web-all-inputs-box
  input {
  text-align: center;
  width: 100px;
  height: 35px;
  padding: 5px;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--dropdown-field-bg-color);
  color: var(--common-white-color);
}

.web-traderProfile-form-field .web-inputs-limit-wrapper .web-divide-line {
  background-color: var(--you-can-change-color);
  width: 430px;
  height: 2px;
}
.web-traderProfile-form-field .web-trade-all {
  gap: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.web-traderProfile-form-field .web-big-input-box {
  width: 170px !important;
}

.web-traderProfile-form-field .web-inputs-limit-wrapper {
  align-items: center;
  gap: 10px;
  width: 100%;
}

.web-traderProfile-form-field .web-scrip-mostly-select-trade {
  background: var(--dropdown-field-bg-color);
  height: 35px;
  width: 170px;
  padding: 5px 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 5px;
}
.web-traderProfile-form-field .web-scrip-option-trade {
  background: var(--scrip-option-trade) !important;
}

.web-traderProfile-form-field .web-trader-type-select-trade {
  background: var(--dropdown-field-bg-color);
  height: 35px;
  width: 170px;
  padding: 5px 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 5px;
}

.web-traderProfile-form-field .web-trader-type-option-trade {
  background: var(--scrip-option-trade) !important;
}

.web-traderProfile-form-field .web-info-icon-img {
  width: 20px;
  height: 20px;
}

.web-traderProfile-form-field .web-traderprofile-close-btn {
  width: 240px;
  height: 45px;
  background: var(--button-setting-close-color);
  text-align: center;
  color: var(--common-white-color);
  font-size: 18px;
  border-radius: 20px;
  font-weight: 600;
}

.web-traderProfile-form-field .web-traderprofile-save-btn {
  width: 240px;
  height: 45px;
  color: var(--main-bg-color);
  background-color: var(--main-primary-color);
  font-size: 18px;
  border-radius: 55px;
  font-weight: 600;
}
.web-traderProfile-form-field .web-traderprofile-footer {
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  padding-top: 0px;
}

.web-traderProfile-form-field .web-modal-footer {
  border-top: none !important;
}
.web-trader-profile-heading-main {
  color: var(--main-primary-color);

  text-align: center;

  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 70px;
}

.web-trader-profile-heading-sub {
  color: var(--common-white-color);

  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}
.web-desired-rr-input-trader-profilefile {
  font-size: 16px;
  font-weight: 500;
  color: var(--common-white-color);
}
.web-error-message-red {
  font-size: 12px;
  margin-left: 20px;
  width: 100%;
  height: 15px;
  margin-bottom: 5px;
  color: red;
}

.web-limit-heading-mr-right {
  margin-right: 65px;
}

.web-edit-trader-broker-id-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1em;
}

.web-all-inputs-box {
  width: 550px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--all-input-box-bg);
  color: var(--common-white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2em 1em;
}
.web-broker-id-name-box {
  text-align: center;
  width: 120px;
  padding: 0.5em;
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--dropdown-field-bg-color);
  color: var(--common-white-color);
  opacity: 0.5;
  cursor: default;
}
@media only screen and (max-height: 900px) {
  .web-trader-profile-container-main {
    max-height: 70% !important;
    overflow-y: scroll;
  }
  .web-traderProfile-form-field {
    overflow-y: hidden;
    height: auto;
  }
}

.web-risk-profit-target {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.percent-inside-input {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  font-size: 18px;
}

.rupees-inside-input {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  font-size: 18px;
}
.web-traderProfile-form-field
  .web-inputs-limit-wrapper
  .web-all-inputs-box
  .padding-left-input {
  padding-left: 30px;
}
.web-traderProfile-form-field
  .web-inputs-limit-wrapper
  .web-all-inputs-box
  .padding-right-input {
  padding-right: 35px;
}

.web-traderProfile-form-field
  .web-inputs-limit-wrapper
  .web-all-inputs-box
  .padding-right-big-input {
  padding-right: 30px;
}

.web-traderProfile-form-field
  .web-inputs-limit-wrapper
  .web-all-inputs-box
  .padding-left-big-input {
  padding-left: 30px;
}
