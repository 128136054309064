.changePassword-container {
  width: 100%;
  max-height: 70%;
  height: auto;
  color: var(--common-white-colo);
  padding: 20px 10px;
  border-radius: 15px;
  background: var(--medium-bg-color);
  border: none !important;
  overflow: auto;
}
.changer-your-password-heading {
  color: var(--main-primary-color);
  font-size: 28px;
  font-weight: 600;
  line-height: 130%;
}
.change-password-form-container {
  flex-direction: column;
  gap: 3em;
}
.display-flex-align-justy-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.change-pass-input-lable-container {
  display: flex;
  flex-direction: column;
  gap: 3em;
  justify-content: flex-start;

  width: 100%;
  padding: 0.5em 1em;
}
.current-new-password-lable {
  font-size: 17px;
  opacity: 0.35;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 5px;
  color: var(--common-white-color);
}
.change-password-error-text {
  color: var(--common-white-color);
  width: 100%;
}

.input-current-new-password {
  border-radius: 4px;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background: var(--new-password-input-bg);

  width: 100%;
  height: 49px;
  padding: 8px 32px;
}
.passwordInputRow {
  color: var(--common-white-color);
  position: relative;
}
.iconSPasswordcp {
  position: absolute;
  right: 20px;
}
.password-change-save-btn {
  width: 15vw;
  height: 45px;
  border-radius: 55px;
  background-color: var(--main-primary-color) !important;
  color: var(--main-bg-color);
  font-size: 18px;
  font-weight: 700;
}
